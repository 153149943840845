import { useApolloClient } from "@apollo/client";
import cx from "classnames";
import {
  Form,
  Formik,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import PropTypes from "prop-types";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { boolean, object } from "yup";

import {
  Alert,
  Button,
  Card,
  Heading,
  Icon,
  Spinner,
} from "@icg360/design-system";

import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";
import { MainLayout } from "components/shared/main-layout";
import {
  OptionalCoveragesQuery,
  useOptionalCoveragesQuery,
  usePurchaseCoverageMutation,
} from "gql/__generated__/hooks";
import { optionalCoveragesQuery } from "gql/queries";
import { en as locale } from "locale";
import {
  formatCurrency,
  formatDate,
  logError,
  scrollToTop,
  trackEvent,
} from "utils";

import { CoverageContext, useCoverages } from "../hooks";
import { CoverageError, actionTypes, stepsReducer, useSteps } from "./hooks";
import styles from "./update-coverage.module.scss";

const CoverageCard = lazy(() => import("./coverage-card/index"));

export type CoverageUpdateFormSchema = {
  IDENTITY_FRAUD?: boolean;
  MECHANICAL_BREAKDOWN?: boolean;
  SERVICE_LINE?: boolean;
};

type CoverageUpdateFormProps = {
  setCoveragePage: (string) => void;
  step: string;
  error: CoverageError;
  dispatchError: (CoverageError) => void;
  coverages: OptionalCoveragesQuery["getEligibleCoverages"]["coverages"];
  setCoverageStep: () => void;
  setReviewStep: () => void;
};

const CoverageUpdateForm = ({
  setCoveragePage,
  step,
  error,
  dispatchError,
  coverages,
  setCoverageStep,
  setReviewStep,
}: CoverageUpdateFormProps) => {
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<CoverageUpdateFormSchema>();
  const [cart, setCart] = useState<Record<string, number>>({});
  const [date, setDate] = useState("");
  const [total, setTotal] = useState(0);
  const [leftLoading, setLeftLoading] = useState<number>(
    coverages?.length ?? 0
  );
  const {
    coveragePage: { y: yOffset },
  } = useContext(CoverageContext);

  // for some reason, this value is 0 at render and changes to the actual length later.
  useEffect(() => {
    setLeftLoading(coverages?.length ?? 0);
  }, [coverages]);

  useEffect(() => {
    const evalTotal = Object.values(cart).reduce((a, b) => a + b, 0);
    setTotal(evalTotal);

    if (error.set && evalTotal > 0) {
      dispatchError({ type: "unset" });
    }
  }, [cart, dispatchError, error.set]);

  const close = (e) => {
    e.preventDefault();

    setCoveragePage({ y: yOffset, policyId: "", type: "close" });
  };

  const next = (e) => {
    e.preventDefault();

    if (total > 0) {
      dispatchError({ type: "unset" });
      setReviewStep();
    } else {
      dispatchError({ type: "minimum" });
    }
  };

  let Main = (
    <>
      <CoveragesLoading />
      {/* we have to call each coverage so their prorated values can be calculated, individual cards will remain hidden until all have been loaded */}
      {coverages?.map((coverage, index) => (
        <CoverageCard
          dispatchError={dispatchError}
          step={step}
          coverage={coverage}
          setCart={setCart}
          setDate={setDate}
          leftLoading={leftLoading}
          setLeftLoading={setLeftLoading}
          key={index}
        />
      ))}
    </>
  );

  if (leftLoading <= 0) {
    Main = (
      <Card
        title={locale.extendedCoverages.step.coverages.title}
        className={styles.sectionCard}
      >
        {error.set && (
          <Alert
            appearance="danger"
            title={error.title}
            description={error.description}
          />
        )}
        <div>
          <p className={styles.description}>
            Coverages you purchase are added to your policy and pro-rated for
            your current policy term.
          </p>
          <div className={styles.cardContainer}>
            {coverages?.map((coverage, index) => (
              <CoverageCard
                dispatchError={dispatchError}
                step={step}
                coverage={coverage}
                setCart={setCart}
                setDate={setDate}
                leftLoading={leftLoading}
                setLeftLoading={setLeftLoading}
                key={index}
              />
            ))}
          </div>
          <div className={styles.cardFooter}>
            <Button
              onClick={(e) => close(e)}
              appearance="tertiary"
              data-testid="coverageGoBackButton"
            >
              Cancel
            </Button>

            <Button
              appearance="primary"
              size="default"
              type="button"
              onClick={(e) => next(e)}
              disabled={error.set && total <= 0}
              data-testid="reviewButton"
              role="button"
            >
              Review
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  if (step === actionTypes.review && leftLoading <= 0) {
    Main = (
      <Card
        title={locale.extendedCoverages.step.review.title}
        className={styles.sectionCard}
      >
        {error.set && (
          <Alert
            appearance="danger"
            title={error.title}
            description={error.description}
          />
        )}
        <div>
          <p className={styles.description}>
            {`Your coverage will start on ${formatDate(
              date,
              "YYYY-MM-DD"
            )} and will be added to your next billing statement.`}
          </p>

          <div className={styles.cardContainer}>
            {coverages?.map(
              (coverage, index) =>
                coverage?.name &&
                values[coverage.name] && (
                  <CoverageCard
                    dispatchError={dispatchError}
                    step={step}
                    coverage={coverage}
                    setCart={setCart}
                    setDate={setDate}
                    leftLoading={leftLoading}
                    setLeftLoading={setLeftLoading}
                    key={index}
                  />
                )
            )}

            <div className={styles.coverageTotal}>
              <InlineDataRow className={styles.proRate}>
                <DataTerm>Your pro-rated price</DataTerm>
                <DataDefinition className={styles.dataDefinition}>
                  {formatCurrency(total, false)}
                </DataDefinition>
              </InlineDataRow>
            </div>
          </div>

          <div className={styles.cardFooter}>
            <Button
              onClick={() => setCoverageStep()}
              appearance="tertiary"
              data-testid="reviewGoBackButton"
            >
              Go back
            </Button>

            <Button
              appearance="primary"
              size="default"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={() => setFieldValue("total", total)}
              data-testid="submitButton"
            >
              Complete purchase
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  if (error.set && error.type !== "minimum" && leftLoading <= 0) {
    Main = (
      <Card className={styles.sectionCard}>
        <div>
          <Alert
            appearance="danger"
            title={error.title}
            description={error.description}
            className={styles.error}
          />
          <div className={styles.cardFooter}>
            <Button
              onClick={() =>
                setCoveragePage({ y: 0, policyId: "", type: "close" })
              }
              appearance="tertiary"
              data-testid="errorCancelButton"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Suspense
      fallback={
        <MainLayout className={styles.maxWidth}>
          <CoveragesLoading />
        </MainLayout>
      }
    >
      <MainLayout className={styles.maxWidth}>
        <Form>{Main}</Form>
      </MainLayout>
    </Suspense>
  );
};

const CoverageHeader = ({ step }) => {
  return (
    <div className={styles.formHeaderContainer}>
      <Container className={styles.maxWidth}>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <div className={styles.formHeader}>
              <Heading
                size="sm"
                className={cx(styles.step, {
                  [styles.active]: step === actionTypes.coverages,
                })}
              >
                Coverages
              </Heading>

              <Icon name="ArrowRight" size="sm" />

              <Heading
                size="sm"
                className={cx(styles.step, {
                  [styles.active]: step === actionTypes.review,
                })}
              >
                Review
              </Heading>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const CoveragesLoading = () => (
  <Card className={styles.sectionCard}>
    <div className={styles.loadingContainer}>
      <Spinner />
      <p>
        <b>{locale.extendedCoverages.loading.title} </b>
        {locale.extendedCoverages.loading.description}
      </p>
    </div>
  </Card>
);

type CoverageUpdateProps = {
  error: CoverageError;
  dispatchError: (CoverageError) => void;
  setCoveragePage: (string) => void;
};

const CoverageUpdate = ({
  error,
  dispatchError,
  setCoveragePage,
}: CoverageUpdateProps) => {
  const client = useApolloClient();
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { refetchPolicy } = useContext(AuthAppDispatchContext);

  const [purchaseCoverage] = usePurchaseCoverageMutation();
  const { data: OptionalCoverages, loading } = useOptionalCoveragesQuery({
    variables: {
      policyID: selectedPolicyId,
    },
    context: {
      clientName: "keystone-api",
    },
  });

  useEffect(() => {
    scrollToTop();
    dispatchError({ type: "unset" });
  }, [dispatchError]);

  useEffect(() => {
    if (error.set) {
      scrollToTop();
    }
  }, [error]);

  const { getEligibleCoverages } = OptionalCoverages ?? {};
  const { coverages } = getEligibleCoverages ?? {};

  const coveragesToShow = useCoverages(coverages);
  const { step, setCoverageStep, setReviewStep } = useSteps({
    reducer: stepsReducer,
  });

  if (loading || !coveragesToShow) {
    return null;
  }

  // map each coverage value to false and return that array
  // if we don't initialize this state, react will throw a warning when we use them in a checkbox
  const initialValues = Object.values(coverages ?? []).reduce(
    (o, coverage) => ({ ...o, [coverage?.name ?? ""]: false }),
    {}
  );

  const validate = async (values) => {
    const coveragesSchemaResolver = Object.values(coverages ?? []).reduce(
      (o, coverage) => ({ ...o, [coverage?.name ?? ""]: boolean() }),
      {}
    );
    const coveragesSchema = object().shape(coveragesSchemaResolver);

    try {
      await validateYupSchema(values, coveragesSchema, true);
    } catch (error) {
      return yupToFormErrors(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { ...coverages } = values;

    async function purchase(eligibleCoverages, coverages) {
      setSubmitting(true);
      const successful: string[] = [];
      const failed: string[] = [];
      for (const coverage of eligibleCoverages) {
        if (coverages[coverage.name]) {
          const { data: purchaseCoverageData, errors } = await purchaseCoverage(
            {
              variables: {
                policyID: selectedPolicyId,
                coverageName: coverage.name,
                protectionValue: coverage.protectionValue,
              },
              context: {
                clientName: "keystone-api",
              },
            }
          );
          const { purchaseCoverage: purchasedTerms } =
            purchaseCoverageData ?? {};

          if (purchasedTerms) {
            trackEvent("Additional coverages -- Purchasing succeeded", {
              coverage: coverage.name,
              policyId: selectedPolicyId,
            });

            successful.push(coverage.name);
          }

          if (errors && errors.length) {
            trackEvent("Additional coverages error -- Purchasing failed", {
              coverage: coverage.name,
              policyId: selectedPolicyId,
            });

            failed.push(coverage.name);
          }
        }
      }

      return { successful, failed };
    }

    try {
      setSubmitting(true);
      const { coverages: eligibleCoverages, ...restOfGetEligibleCoverages } =
        OptionalCoverages?.getEligibleCoverages ?? {};

      const { successful, failed } = await purchase(
        eligibleCoverages,
        coverages
      );
      setSubmitting(false);

      if (failed.length > 0) {
        const numCovered = failed.length + successful.length;

        if (numCovered === failed.length) {
          setCoveragePage({ policyId: selectedPolicyId, type: "danger" });
        } else {
          setCoveragePage({
            policyId: selectedPolicyId,
            type: "warning",
            failed,
          });
        }

        throw new Error(
          `succeeded: ${successful.join(" ")}, failed: ${failed.join(" ")}`
        );
      } else {
        const availableCoverages = eligibleCoverages?.filter(
          (c) => !successful.includes(c?.name ?? "")
        );

        const updatedCoverageData = {
          ...OptionalCoverages,
          getEligibleCoverages: {
            coverages: availableCoverages,
            ...restOfGetEligibleCoverages,
          },
        };

        client.writeQuery({
          query: optionalCoveragesQuery,
          variables: { policyID: selectedPolicyId },
          data: updatedCoverageData,
        });

        setCoveragePage({ policyId: selectedPolicyId, type: "success" });
      }
      refetchPolicy();
    } catch (err) {
      logError(`Coverage update: ${err.message}`);
      dispatchError({});
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <CoverageHeader step={step} />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
      >
        <CoverageUpdateForm
          setCoveragePage={setCoveragePage}
          step={step}
          setCoverageStep={setCoverageStep}
          setReviewStep={setReviewStep}
          coverages={coveragesToShow}
          error={error}
          dispatchError={dispatchError}
        />
      </Formik>
    </div>
  );
};

CoverageUpdate.propTypes = {
  error: PropTypes.shape({
    set: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  dispatchError: PropTypes.func,
  setCoveragePage: PropTypes.func,
};

export default CoverageUpdate;
