import { Field, Form, Formik, useFormikContext } from "formik";
import { useContext } from "react";

import { Button, Card, Heading, addToast } from "@icg360/design-system";

import { Body } from "components/common/body";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider.js";
import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { CheckboxField } from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { POOL_FORM_STEPS, PoolFormStep } from "consts";
import { useSendPropertyProfileUpdateNotificationMutation } from "gql/__generated__/hooks.js";
import { en as locale } from "locale";
import {
  UpdatePoolValueKeys,
  UpdatePoolValues,
} from "pages/property-profile/update-pool/update-pool-form.const";
import UpdatePoolReview from "pages/property-profile/update-pool/update-pool-review";
import { logError, trackEvent } from "utils";

import { useUpdatePool } from "./hooks";
import { UPDATE_POOL_FORM as pageMap } from "./update-pool-form.const";
import styles from "./update-pool.module.scss";

const initialValues: UpdatePoolValues = {
  poolType: null,
  poolFence: null,
  poolFenceLockingGate: null,
  poolFenceMinimumHeight: null,
  aboveGroundPoolMinimumHeight: null,
  divingBoard: null,
  poolSlide: null,
  poolFilled: null,
  poolCovered: null,
  poolImmovableLadder: null,
  poolAttachedToDecking: null,
  poolDeckGateLocks: null,
  confirmNoPool: false,
};

// Converts form values for keystone-api
// Basically, converts nulls to false
const convertValues = (values: UpdatePoolValues) => {
  const {
    divingBoard,
    poolSlide,
    poolType,
    poolFence,
    poolFenceMinimumHeight,
    poolFenceLockingGate,
    aboveGroundPoolMinimumHeight,
    poolFilled,
    poolCovered,
    poolAttachedToDecking,
    poolDeckGateLocks,
    poolImmovableLadder,
  } = values;

  return {
    poolType,
    poolFence: !!poolFence,
    poolFenceMinimumHeight: !!poolFenceMinimumHeight,
    poolFenceLockingGate: !!poolFenceLockingGate,
    aboveGroundPoolMinimumHeight: !!aboveGroundPoolMinimumHeight,
    divingBoard: !!divingBoard,
    poolSlide: !!poolSlide,
    poolFilled: !!poolFilled,
    poolCovered: !!poolCovered,
    poolImmovableLadder: !!poolImmovableLadder,
    poolAttachedToDecking: !!poolAttachedToDecking,
    poolDeckGateLocks: !!poolDeckGateLocks,
  };
};

type BooleanRadioProps = {
  field: UpdatePoolValueKeys;
};

// TODO remove name, className, onChange, checked, and id(?) from Fields when removing radioButtonsUseDs FF PHX-1022
const BooleanRadio = ({ field }: BooleanRadioProps) => {
  const { values, setFieldValue } = useFormikContext<UpdatePoolValues>();
  const fieldValue = values[field];
  return (
    <Field
      name={field}
      component={RadioButtonGroup}
      onChange={(value) => {
        setFieldValue(field, value);
      }}
      value={fieldValue}
      fieldChildren={() => (
        <div className={styles.radioGroup}>
          <Field
            name={field}
            id={field + "Yes"}
            value={true}
            label="Yes"
            className={styles.radio}
            onChange={() => setFieldValue(field, true)}
            component={RadioButtonField}
            checked={fieldValue}
          />
          <Field
            name={field}
            id={field + "No"}
            value={false}
            label="No"
            className={styles.radio}
            onChange={() => setFieldValue(field, false)}
            component={RadioButtonField}
            checked={fieldValue === null ? false : !fieldValue}
          />
        </div>
      )}
    />
  );
};

// TODO remove name, className, onChange, checked, and id(?) from Fields when removing radioButtonsUseDs FF PHX-1022
const PoolTypeRadio = () => {
  const {
    setFieldValue,
    values: { poolType },
    resetForm,
  } = useFormikContext<UpdatePoolValues>();
  return (
    <Field
      name={"poolType"}
      component={RadioButtonGroup}
      onChange={(value) => {
        if (value === "NONE") {
          resetForm();
        }
        setFieldValue("poolType", value);
      }}
      value={poolType}
      fieldChildren={() => (
        <div className={styles.radioGroup}>
          <Field
            name={"poolType"}
            id={"InGround"}
            label={locale.updatePool.inGround}
            value="INGROUNDPOOL"
            className={styles.radio}
            onChange={() => setFieldValue("poolType", "INGROUNDPOOL")}
            component={RadioButtonField}
            checked={poolType === "INGROUNDPOOL"}
          />
          <Field
            name={"poolType"}
            id={"AboveGround"}
            label={locale.updatePool.aboveGround}
            value="ABOVEGROUNDPOOL"
            className={styles.radio}
            onChange={() => setFieldValue("poolType", "ABOVEGROUNDPOOL")}
            component={RadioButtonField}
            checked={poolType === "ABOVEGROUNDPOOL"}
          />
          <Field
            name={"poolType"}
            id={"None"}
            label={"No"}
            value="NONE"
            className={styles.radio}
            onChange={() => {
              resetForm();
              setFieldValue("poolType", "NONE");
            }}
            component={RadioButtonField}
            checked={poolType === "NONE"}
          />
        </div>
      )}
    />
  );
};

type FormQuestionProps = {
  shownCard: PoolFormStep;
};

const FormQuestion = ({ shownCard }: FormQuestionProps) => {
  let Questions;
  if (shownCard === "poolType") {
    Questions = () => <PoolTypeRadio />;
  } else if (shownCard === "fenceDetails") {
    Questions = () => (
      <div className={styles.twoQuestion}>
        {locale.updatePool.questions.fenceHeight}
        <BooleanRadio field={"poolFenceMinimumHeight"} />
        {locale.updatePool.questions.poolFenceLockingGate}
        <BooleanRadio field={"poolFenceLockingGate"} />
      </div>
    );
  } else if (shownCard === "boardOrSlide") {
    Questions = () => (
      <div className={styles.twoQuestion}>
        {locale.updatePool.questions.divingBoard}
        <BooleanRadio field={"divingBoard"} />
        {locale.updatePool.questions.poolSlide}
        <BooleanRadio field={"poolSlide"} />
      </div>
    );
  } else {
    Questions = () => <BooleanRadio field={shownCard} />;
  }

  return (
    <>
      <Heading size="lg">{locale.updatePool.questions[shownCard]}</Heading>
      <br />
      <Questions />
    </>
  );
};

type NavigationButtonsProps = {
  shownCard: PoolFormStep;
  onClose: () => void;
  setShownCard: (PoolFormStep) => void;
  setReview: (boolean) => void;
  setConfirmNone: (boolean) => void;
};

const NavigationButtons = ({
  shownCard,
  onClose,
  setShownCard,
  setReview,
  setConfirmNone,
}: NavigationButtonsProps) => {
  const { values } = useFormikContext<UpdatePoolValues>();
  let next = 1,
    prev = 1;
  const inGround = values.poolType === "INGROUNDPOOL";
  const skipIfInGround: PoolFormStep[] = [
    "aboveGroundPoolMinimumHeight",
    "poolImmovableLadder",
  ];
  const poolFormSteps = inGround
    ? POOL_FORM_STEPS.filter((step) => !skipIfInGround.includes(step))
    : POOL_FORM_STEPS;

  const currentStep = poolFormSteps.indexOf(shownCard);
  const skipNextIfNo: PoolFormStep[] = ["poolFence", "poolAttachedToDecking"];
  const skipNextIfYes: PoolFormStep[] = ["poolFilled"];

  // Same as skipNextIfNo/Yes, but two steps ahead
  let skipPrevIfNo: PoolFormStep[] = ["aboveGroundPoolMinimumHeight"];
  const skipPrevIfYes: PoolFormStep[] = ["poolImmovableLadder"];
  if (inGround) {
    skipPrevIfNo = ["boardOrSlide", "poolAttachedToDecking"];
  }

  // Determine next step
  if (
    (skipNextIfNo.includes(shownCard) && !values[shownCard]) ||
    (skipNextIfYes.includes(shownCard) && values[shownCard])
  ) {
    next = next + 1;
  }
  const nextStep = currentStep + next;
  let nextFunc = () => setReview(true);
  if (shownCard === "poolType" && values.poolType === "NONE") {
    nextFunc = () => setConfirmNone(true);
  } else if (nextStep < poolFormSteps.length) {
    nextFunc = () => setShownCard(poolFormSteps[nextStep]);
  }

  // Determine previous step
  const prevCard = poolFormSteps[currentStep - 2];
  if (skipPrevIfNo.includes(shownCard)) {
    if (!values[prevCard]) {
      prev = prev + 1;
    }
  } else if (skipPrevIfYes.includes(shownCard)) {
    if (values[prevCard]) {
      prev = prev + 1;
    }
  }
  const prevStep = currentStep - prev;
  const goBackFunc =
    prevStep >= 0
      ? () => setShownCard(poolFormSteps[prevStep])
      : () => onClose();

  let disableNext: boolean;
  if (shownCard === "fenceDetails") {
    disableNext =
      values.poolFenceLockingGate === null ||
      values.poolFenceMinimumHeight === null;
  } else if (shownCard === "boardOrSlide") {
    disableNext = values.divingBoard === null || values.poolSlide === null;
  } else {
    disableNext = values[shownCard] === null;
  }

  return (
    <div className={styles.navigation}>
      <Button appearance="tertiary" onClick={goBackFunc}>
        {locale.propertyProfile.goBack}
      </Button>
      <Button
        appearance="primary"
        size="default"
        onClick={nextFunc}
        disabled={disableNext}
        data-testid="pool-continue-btn"
      >
        {locale.propertyProfile.continue}
      </Button>
    </div>
  );
};

type UpdatePoolProps = {
  onClose: () => void;
  onUpdateSuccess: () => void;
};

const UpdatePool = ({ onClose, onUpdateSuccess }: UpdatePoolProps) => {
  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    try {
      setSubmitting(true);
      trackEvent("Property pool - Updates submitted");
      const endorsementUpdates = convertValues(values);
      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates,
        },
        context: {
          clientName: "keystone-api",
        },
      });

      if (data) {
        trackEvent("Property pool - Update completed");
        onUpdateSuccess();
        onClose();
        addToast(
          "We have received your update! Once validated, you will see these changes reflected here on your property profile.",
          { icon: true, duration: 10000 }
        );
      }
    } catch (error) {
      logError(`Update pool: ${error.message}`);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property pool - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <UpdatePoolForm onClose={onClose} />
    </Formik>
  );
};

type UpdatePoolFormProps = {
  onClose: () => void;
};

const UpdatePoolForm = ({ onClose }: UpdatePoolFormProps) => {
  const { status, values } = useFormikContext<UpdatePoolValues>();

  const {
    setShownCard,
    setReview,
    setConfirmNone,
    review,
    shownCard,
    confirmNone,
    error,
  } = useUpdatePool({
    status,
    values,
  });
  const {
    updatePool: { sidebars },
  } = locale;

  let main = (
    <Card className={styles.updatePool}>
      <Form>
        <FormQuestion shownCard={shownCard} />
      </Form>
      <NavigationButtons
        shownCard={shownCard}
        onClose={onClose}
        setShownCard={setShownCard}
        setReview={setReview}
        setConfirmNone={setConfirmNone}
      />
    </Card>
  );

  if (review) {
    main = (
      <UpdatePoolReview
        setReview={setReview}
        setShownCard={setShownCard}
        error={error}
      />
    );
  } else if (confirmNone) {
    main = (
      <ConfirmNoPool
        setShownCard={setShownCard}
        setReview={setReview}
        setConfirmNone={setConfirmNone}
        confirmNoPool={values.confirmNoPool}
      />
    );
  }

  let sidebar = <></>;
  if (pageMap[shownCard].sidebar) {
    sidebar = (
      <PropertyProfileSidebar
        headline={pageMap[shownCard].sidebar?.title}
        body={pageMap[shownCard].sidebar?.content}
        iconName="Question"
      />
    );
  }
  if (review) {
    sidebar = (
      <PropertyProfileSidebar
        headline={sidebars.titles.nextSteps}
        body={sidebars.content.nextSteps}
        iconName="Question"
      />
    );
  } else if (confirmNone) {
    sidebar = <></>;
  }

  return (
    <>
      <ProgressBar progress={review ? 95 : pageMap[shownCard].progress} />
      <MainLayout sidebar={sidebar}>{main}</MainLayout>
    </>
  );
};

type ConfirmNoPoolProps = {
  setShownCard: (PoolFormStep) => void;
  setReview: (boolean) => void;
  setConfirmNone: (boolean) => void;
  confirmNoPool: boolean;
};

const ConfirmNoPool = ({
  setShownCard,
  setReview,
  setConfirmNone,
  confirmNoPool,
}: ConfirmNoPoolProps) => {
  return (
    <Card className={styles.reviewCard}>
      <Form>
        <Stack>
          <Heading size="lg">{locale.updatePool.pleaseConfirm}</Heading>
          <Body>{locale.updatePool.confirmDescription}</Body>

          <Field
            component={CheckboxField}
            name={"confirmNoPool"}
            label={"Yes, I agree."}
            className={styles.acknowledgeCheckbox}
            checked={confirmNoPool}
          />

          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onClick={() => {
                setConfirmNone(false);
                setShownCard(POOL_FORM_STEPS[0]);
              }}
            >
              {locale.propertyProfile.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              type="button"
              disabled={!confirmNoPool}
              onClick={() => {
                setConfirmNone(false);
                setReview(true);
              }}
              data-testid="pool-no-pool-btn"
            >
              {locale.propertyProfile.continue}
            </Button>
          </div>
        </Stack>
      </Form>
    </Card>
  );
};

export default UpdatePool;
