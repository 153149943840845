import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { Button, ButtonProps } from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import styles from "./property-update-buttons.module.scss";
import {
  PropertyUpdateRoofContext,
  PropertyUpdateRoofContextState,
} from "./property-update-roof";

type PropertyUpdateButtonsProps = {
  primaryDisabled?: boolean;
  primaryOnClick?: ButtonProps["onClick"];
  primaryPath?: string;
  primaryText?: string;
  step: number;
};

export const PropertyUpdateButtons = ({
  primaryDisabled,
  primaryOnClick,
  primaryPath,
  primaryText,
  step,
}: PropertyUpdateButtonsProps) => {
  const navigate = useNavigate();
  const { formNavigate } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );
  const isMobile = useMediaQuery({ maxWidth: screenSizes.tabletS });

  return (
    <footer className={styles.footer}>
      <div className={styles.navButtons}>
        <Button
          fluid={!isMobile}
          size={isMobile ? "sm" : "default"}
          appearance="tertiary"
          onClick={() => navigate("/my/property")}
        >
          Cancel
        </Button>
        <Button
          fluid={!isMobile}
          size={isMobile ? "sm" : "default"}
          appearance="secondary"
          disabled={step === 1}
          className={step === 1 && styles.hidden}
          onClick={() => formNavigate(-1)}
        >
          Back
        </Button>
        <Button
          fluid
          size={isMobile ? "sm" : "default"}
          disabled={primaryDisabled}
          onClick={
            primaryOnClick ??
            (primaryPath ? () => formNavigate(primaryPath) : undefined)
          }
        >
          {primaryText ?? "Next"}
        </Button>
      </div>
    </footer>
  );
};
