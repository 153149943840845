import classNames from "classnames";
import get from "lodash.get";
import PropTypes from "prop-types";
import { useRef } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";

import { RadioButton } from "components/common/radio-button";
import { formFieldProps, formProps } from "consts";
import { scrollToError } from "utils";

import styles from "./radio-button-field-old.module.scss";

export const RadioButtonFieldOld = ({
  field: { name, onChange, onBlur, isSubmitting },
  form: { errors },
  id,
  label,
  className,
  selected,
  ...props
}) => {
  const errorsField = get(errors, name);

  const inputRef = useRef(null);
  scrollToError(name, inputRef, errorsField, isSubmitting, errors);
  return (
    <div className={classNames(styles.inlineBtnContainer, className)}>
      <RadioButton
        name={name}
        id={id}
        checked={id === selected}
        onChange={(e) => {
          onChange(e);
          if (props.onChange) props.onChange(e);
        }}
        onBlur={onBlur}
        {...props}
        value={id}
      >
        <FormLabel htmlFor={id} className={styles.radioButtonLabel}>
          {label}
        </FormLabel>
      </RadioButton>
    </div>
  );
};

RadioButtonFieldOld.propTypes = {
  field: PropTypes.shape(formFieldProps),
  form: PropTypes.shape(formProps),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export const RadioButtonGroupOld = ({
  error,
  touched,
  title,
  className,
  ...props
}) => (
  <FormGroup className={className}>
    <fieldset>
      <legend className={styles.formLegend}>{title}</legend>
      <props.fieldChildren />
      {touched && (
        <span data-testid="field-error" className={styles.fieldError}>
          {error}
        </span>
      )}
    </fieldset>
  </FormGroup>
);

RadioButtonGroupOld.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  fieldChildren: PropTypes.any.isRequired,
};
