export const POPUPS = [
  "invalidPolicyModal",
  "enrolledInEasyPayModal",
  "easyPayEnrollmentModal",
  "paperlessModal",
  "",
] as const;

export type Popup = (typeof POPUPS)[number];
export const isPopup = (value: string): value is Popup => {
  return POPUPS.includes(value as Popup);
};

export const modalCookies = {
  easyPayEnrollmentModal: "mss-easypay-modal-dismissed",
  paperlessModal: "mss-paperless-modal-dismissed",
};
