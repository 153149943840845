import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router";
import { useLoaderData } from "react-router-dom";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { PropertyProfileLoaderData } from "components/root/router";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { useFlags } from "utils";

import styles from "./property-update-roof.module.scss";

export type PropertyUpdateRoofContextState = {
  roofYear?: number;
  roofMaterial?: string;
  files?: FileList;
  prevRoofYear?: number;
} | null;

export const PropertyUpdateRoofContext = createContext<{
  state: PropertyUpdateRoofContextState;
  setState: Dispatch<SetStateAction<PropertyUpdateRoofContextState>>;
}>({ state: null, setState: () => null });

export const PropertyUpdateRoof = () => {
  const { universalLogin, retireProxy } = useFlags();
  const { selectedPolicyId } = useContext(AuthAppContext);
  const loaderData = useLoaderData() as PropertyProfileLoaderData;
  const { data: propertyProfileData, loading } =
    useUserPropertyProfileDataQuery({
      variables: {
        policyID: selectedPolicyId,
      },
      skip: !selectedPolicyId,
    });
  const propertyData =
    universalLogin && retireProxy
      ? loaderData?.propertyProfile
      : propertyProfileData?.userPropertyProfileData;
  const roofYear = propertyData?.constructionYearRoof;

  const { formNavigate, setFields, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  useEffect(() => {
    if (!state?.roofYear || !state?.roofMaterial) {
      formNavigate("./");
    }
  }, [formNavigate, state]);

  useEffect(() => {
    if (roofYear) {
      setFields({ prevRoofYear: roofYear });
    }
  }, [roofYear, setFields]);

  if (loading || !selectedPolicyId) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet />;
};

export const PropertyUpdateRoofFlow = () => {
  const [state, setState] = useState<PropertyUpdateRoofContextState>(null);
  return (
    <PropertyUpdateRoofContext.Provider value={{ state, setState }}>
      <PropertyUpdateRoof />
    </PropertyUpdateRoofContext.Provider>
  );
};
