import { useContext } from "react";

import { Card, Heading } from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { en as locale } from "locale";
import { formatPhone, isFormattedPhone } from "utils";

import styles from "./insurance-representative.module.scss";

export const InsuranceRepresentative = () => {
  const { userInsuranceRep } = useContext(AuthAppContext);
  const {
    agencyName,
    agentStreetNumber,
    agentStreetName,
    agentZipCode,
    agentCity,
    agentState,
    agentEmail,
    agentPhone: unFormattedPhone,
  } = userInsuranceRep ?? {};

  const agentPhone = isFormattedPhone(unFormattedPhone)
    ? unFormattedPhone
    : formatPhone(unFormattedPhone || "");

  return (
    <Card>
      <Heading size="md" className={styles.insuranceHeadline}>
        {locale.titles.containers.insuranceRepresentative}
      </Heading>
      <Stack>
        <span className={styles.infoSubheaderText}>
          {locale.supportSidebars.insuranceRep}
        </span>
      </Stack>

      <Stack className={styles.infoStack} gap="sm">
        <div>
          <span className={styles.agencyName}>{agencyName}</span>
          <span>
            {/* agent possibly missing street address */}
            {agentStreetNumber && agentStreetName && (
              <>
                {`${agentStreetNumber} ${agentStreetName}`}
                <br />
              </>
            )}
            {`${agentCity}, ${agentState} ${agentZipCode}`}
          </span>
        </div>
        <EmailIconLink email={agentEmail || ""} />
        <PhoneIconLink phone={agentPhone || ""} />
      </Stack>
    </Card>
  );
};
