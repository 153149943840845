import { ReactNode } from "react";

import { PoolFormStep } from "consts";
import { en as locale } from "locale";

const {
  updatePool: { sidebars },
} = locale;

type UpdatePoolFormContent = {
  progress: number;
  sidebar?: {
    title: string;
    content: ReactNode;
  };
};

export const UPDATE_POOL_FORM: Record<PoolFormStep, UpdatePoolFormContent> = {
  poolType: {
    progress: 10,
    sidebar: {
      title: sidebars.titles.support,
      content: sidebars.content.support,
    },
  },
  poolFence: {
    progress: 20,
    sidebar: {
      title: sidebars.titles.poolSafety,
      content: sidebars.content.poolSafety,
    },
  },
  fenceDetails: {
    progress: 25,
    sidebar: {
      title: sidebars.titles.fencing,
      content: sidebars.content.fencing,
    },
  },
  aboveGroundPoolMinimumHeight: {
    progress: 30,
  },
  boardOrSlide: {
    progress: 40,
  },
  poolFilled: {
    progress: 50,
  },
  poolCovered: {
    progress: 60,
    sidebar: {
      title: sidebars.titles.poolCovers,
      content: sidebars.content.poolCovers,
    },
  },
  poolImmovableLadder: {
    progress: 70,
  },
  poolAttachedToDecking: {
    progress: 80,
  },
  poolDeckGateLocks: {
    progress: 90,
    sidebar: {
      title: sidebars.titles.gateLock,
      content: sidebars.content.gateLock,
    },
  },
};

type PoolTypes = "NONE" | "INGROUNDPOOL" | "ABOVEGROUNDPOOL";

export type UpdatePoolValueKeys =
  | "poolType"
  | "divingBoard"
  | "poolAttachedToDecking"
  | "poolCovered"
  | "poolDeckGateLocks"
  | "poolFence"
  | "poolFenceLockingGate"
  | "poolFenceMinimumHeight"
  | "poolFilled"
  | "poolImmovableLadder"
  | "poolSlide"
  | "aboveGroundPoolMinimumHeight"
  | "confirmNoPool";

export type UpdatePoolValues = {
  poolType: PoolTypes | null;
  divingBoard: boolean | null;
  poolAttachedToDecking: boolean | null;
  poolCovered: boolean | null;
  poolDeckGateLocks: boolean | null;
  poolFence: boolean | null;
  poolFenceLockingGate: boolean | null;
  poolFenceMinimumHeight: boolean | null;
  poolFilled: boolean | null;
  poolImmovableLadder: boolean | null;
  poolSlide: boolean | null;
  aboveGroundPoolMinimumHeight: boolean | null;
  confirmNoPool: boolean;
};
