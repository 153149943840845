import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Link as DSLink, Heading } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CookieNames, getCookie, trackEvent, useFlags } from "utils";

import styles from "./leakbot-banner.module.scss";

export const LeakBotBanner = () => {
  const navigate = useNavigate();
  const { leakbotNyPromo } = useFlags();
  const { userDetails } = useContext(AuthAppContext);

  const isEnrolledInLeakBot = userDetails?.isEnrolledInLeakBot ?? false;
  const policyNumber = userDetails?.currentTerm?.policyNumber ?? "";

  // show success banner one time
  const tracker: CookieNames = `mss-leakbot-newly-enrolled-${policyNumber}`;
  const isNewlyEnrolled = getCookie(tracker);

  useEffect(() => {
    if (userDetails && leakbotNyPromo && !isEnrolledInLeakBot) {
      trackEvent("LeakBot - Banner Displayed");
    }
  }, [isEnrolledInLeakBot, leakbotNyPromo, userDetails]);

  if (
    !userDetails ||
    !leakbotNyPromo ||
    (isEnrolledInLeakBot && !isNewlyEnrolled)
  ) {
    return null;
  }

  const onClickCTA = () => {
    trackEvent("LeakBot - Sign Up clicked");
    navigate("/my/leakbot");
  };

  const onClickLearnMore = () => {
    trackEvent("LeakBot - Learn More clicked");
    navigate("/my/leakbot");
  };

  return (
    <Container>
      <div className={styles.lbWrap}>
        {!isEnrolledInLeakBot && !isNewlyEnrolled && (
          <LeakBotSignUp onCTA={onClickCTA} onLearnMore={onClickLearnMore} />
        )}
        {isNewlyEnrolled && (
          <Alert
            title="Thank you for signing up for a LeakBot!"
            description="Once your order has been processed, LeakBot will send you a confirmation email."
            appearance="success"
          />
        )}
      </div>
    </Container>
  );
};

const LeakBotSignUp = ({
  onCTA,
  onLearnMore,
}: {
  onCTA: () => void;
  onLearnMore: () => void;
}) => {
  return (
    <div className={styles.lbBanner}>
      <div className={styles.lbImgWrapper}>
        <img
          className={styles.lbImg}
          src="/assets/images/leakbot-and-phone.png"
          alt="a leakbot device and a phone"
        />
      </div>
      <div className={styles.lbBannerContent}>
        <div className={styles.lbCopy}>
          <Heading size="sm">
            Everyday, 14,000 homeowners deal with water leak emergencies.
          </Heading>
          <p>
            Protect your home against small and hidden leaks that can go
            unnoticed and cause major water damage.{" "}
            <DSLink onClick={onLearnMore}>Learn more</DSLink>
          </p>
        </div>
        <div className={styles.lbButtonWrapper}>
          <Button
            appearance="primary"
            onClick={onCTA}
            data-testid="leakbot-banner-btn"
            fluid
          >
            Sign up today
          </Button>
        </div>
      </div>
    </div>
  );
};
