import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { object as yupObject, string as yupString } from "yup";

import { Button, TextInput } from "@icg360/design-system";

import { useVerifyRoofShingleResealIntakeEligibilityLazyQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { en as locale } from "locale";
import {
  ShingleContext,
  ShingleContextState,
} from "pages/shingle-repair-pilot/index";
import ShingleRepairLayout from "pages/shingle-repair-pilot/shingle-repair-layout";
import { getShingleLandingPath } from "pages/shingle-repair-pilot/shingle-repair-pilot-utils";
import { obscurePolicyId } from "pages/shingle-repair-pilot/utils";
import { logError, trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const INVALID_ZIP_MESSAGE = "Please enter a valid 5-digit property zip code.";
const MISMATCHED_ZIP_MESSAGE =
  "Your zip code does not match with the property zip code we have on file.";
const ALREADY_REGISTERED_MESSAGE =
  "Sorry, you have already signed up for this program. Stay tuned to receive more information from us. Please contact your agent for more information";
const NOT_ELIGIBLE_MESSAGE =
  "Sorry, this policy is not eligible to sign up for the program. Please contact your agent for more information.";

type FindPolicyFormInputs = {
  policyId?: string;
  zipCode: string;
};

const zipCodeShape = yupString()
  .required(INVALID_ZIP_MESSAGE)
  .matches(/^[0-9]{5}(-[0-9]{4})?$/, INVALID_ZIP_MESSAGE);

const fullSchema = yupObject()
  .shape({
    policyId: yupString()
      .required(locale.formFieldValidations.policyNumber)
      .matches(
        /^[a-zA-Z0-9]{10}([a-zA-Z0-9])?([a-zA-Z0-9])?$/,
        locale.formFieldValidations.policyNumber
      ),

    zipCode: zipCodeShape,
  })
  .required();

const zipOnlyschema = yupObject()
  .shape({
    zipCode: zipCodeShape,
  })
  .required();

const FindPolicy = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const { state, setFields, setFieldsAndNavigate } =
    useMultiStepFormState<ShingleContextState>(ShingleContext);

  const showPolicyIdTextInput = !searchParams.get("policyId");
  const policyId = searchParams.get("policyId") ?? "";
  const firstName = searchParams.get("firstName") ?? "";
  const lastName = searchParams.get("lastName") ?? "";
  const email = searchParams.get("email") ?? "";
  const phone = searchParams.get("phone") ?? "";

  useEffect(() => {
    trackEvent("shinglesForm_step1_load");
  }, []);

  useEffect(() => {
    setFields({ policyId, firstName, lastName, email, phoneNumber: phone });
  }, [firstName, lastName, policyId, setFields, email, phone]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<FindPolicyFormInputs>({
    defaultValues: { zipCode: "", policyId },
    resolver: yupResolver(showPolicyIdTextInput ? fullSchema : zipOnlyschema),
    mode: "onTouched",
  });

  const zipCodeWatch = watch("zipCode");

  useEffect(() => {
    setZipCodeError(null);
  }, [zipCodeWatch]);

  const [verifyRoofShingleResealIntakeEligibility] =
    useVerifyRoofShingleResealIntakeEligibilityLazyQuery({
      context: {
        clientName: "keystone-api",
      },
    });

  const handlePolicyLookup = async (data) => {
    trackEvent("Fetching policy info for shingle pilot", {
      policyId: data.policyId,
      zipCode: data.zipCode,
    });
    setSubmitting(true);
    try {
      const { data: eligibilityData, error } =
        await verifyRoofShingleResealIntakeEligibility({
          variables: { policyId: data.policyId, postal: data.zipCode },
        });

      if (error) {
        if (
          [
            "Policy does not exist",
            "The postal code was not a match for policy",
          ].includes(error.message)
        ) {
          setZipCodeError(MISMATCHED_ZIP_MESSAGE);
        } else if (
          error.message ===
          "An intake registration already exists for this PolicyId"
        ) {
          setZipCodeError(ALREADY_REGISTERED_MESSAGE);
        } else {
          throw error;
        }
        return;
      }

      const { eligible, address, insightPolicyId } =
        eligibilityData?.verifyRoofShingleResealIntakeEligibility ?? {};

      // I don't think this can happen right now.
      // If they aren't eligible there should be an accompanying error caught above
      if (!eligible) {
        setZipCodeError(NOT_ELIGIBLE_MESSAGE);
        return;
      }

      trackEvent("shinglesForm_step1_ContinueClicked");
      setFieldsAndNavigate("policy-confirmation", {
        policyId: data.policyId,
        propertyAddress: address,
        insightPolicyId,
      });
    } catch (e) {
      logError(`Shingle repair pilot - find policy: ${e.message}`);
      trackEvent("Error fetching policy info in shingle form", { error: e });
      setZipCodeError(
        "There was an error trying to fetch your policy details."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ShingleRepairLayout
      title="Thanks for your interest in LiftLock's Wind Defense Research Program!"
      description="As a SageSure customer, you're invited to sign up for a chance to have your shingles resealed in advance of a severe storm's projected landfall with LiftLock’s Wind Defense Research Program."
      stepNumber={1}
    >
      {!showPolicyIdTextInput ? (
        <div>
          <div className={styles.policyNumberLabel}>Policy number</div>
          <div>{obscurePolicyId(state?.policyId ?? "")}</div>
        </div>
      ) : null}
      <form
        onSubmit={handleSubmit(handlePolicyLookup)}
        className={styles.formWrapper}
      >
        {showPolicyIdTextInput ? (
          <TextInput
            {...register("policyId")}
            label="Policy number"
            isRequired
            errorMessage={errors.policyId?.message}
            isError={!!errors.policyId}
          />
        ) : null}
        <TextInput
          {...register("zipCode")}
          label="Zip code"
          isRequired
          errorMessage={errors.zipCode?.message || zipCodeError}
          isError={!!errors.zipCode || !!zipCodeError}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            appearance="tertiary"
            onClick={() => {
              trackEvent("shinglesForm_step1_CancelClicked");
              navigate(getShingleLandingPath(state));
            }}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} loading={submitting}>
            Continue
          </Button>
        </div>
      </form>
    </ShingleRepairLayout>
  );
};

export default FindPolicy;
