import { Heading, Span } from "@icg360/design-system";

import { PROPERTY_ROOF_UPDATE_TYPE } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { PropertyUpdateRoofContext, PropertyUpdateRoofContextState } from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { FileThumbnails } from "./file-thumbnails";
import styles from "./property-update-roof.module.scss";

export const RoofReview = () => {
  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  const onSubmit = () => {
    // TODO
    formNavigate("../success");
  };

  return (
    <PropertyUpdateLayout
      step={4}
      heading="Update roof details"
      buttonProps={{
        primaryOnClick: onSubmit,
        primaryText: "Yes, submit",
      }}
    >
      <div className={styles.roofReview}>
        <Heading size="md" className={styles.reviewHeading}>
          Does this look right?
        </Heading>
        <Span>
          Year replaced: <Span bold>{state?.roofYear}</Span>
        </Span>
        <Span>
          Material:{" "}
          <Span bold>
            {PROPERTY_ROOF_UPDATE_TYPE[state?.roofMaterial].label}
          </Span>
        </Span>
        <div>
          <Span>Proof of update:</Span>
          <FileThumbnails files={state?.files} />
        </div>
      </div>
    </PropertyUpdateLayout>
  );
};
