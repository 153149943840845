import {
  array,
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types";

export const userBillingProp = {
  userBilling: shape({
    accounting: shape({
      minimumPayment: string.isRequired,
      totalBalance: string.isRequired,
      invoiceAmountCurrent: string.isRequired,
      paymentPlan: shape({ planType: string.isRequired }),
      easyPayAccount: shape({ lastFourDigits: string, paymentMethod: string }),
    }),
    mortgagees: array.isRequired,
  }),
};

export const latestDocProp = {
  route: string.isRequired,
  label: string.isRequired,
  group: string.isRequired,
};

export const userDocumentsProp = {
  userDocuments: shape({
    grouped: array.isRequired,
    latest: shape({
      declaration: shape(latestDocProp),
      invoice: shape(latestDocProp),
    }),
  }),
};

export const addressProp = {
  street1: string.isRequired,
  street2: string,
  city: string.isRequired,
  state: string.isRequired,
  zip: string.isRequired,
};

export const insuredPreferences = {
  easyPayEnrollmentStatus: string,
};

export const userDetailsProp = {
  userDetails: shape({
    currentTerm: shape({
      policyNumber: string.isRequired,
      effectiveDate: string.isRequired,
      effectiveDatePolicyTerm: string.isRequired,
      expirationDate: string.isRequired,
      totalPremium: string.isRequired,
      coverageA: string,
      coverageB: string,
      coverageC: string,
      coverageD: string,
      coverageE: string,
      coverageEPerOccurrence: string,
      coverageEAggregate: string,
      coverageF: string,
      hurricaneDeductibleAmount: string,
      allOtherPerilsDeductible: string,
      dbaName: string,
      windHailDeductible: string,
      propertyDamageLiabilityDeductiblePerClaim: string,
      propertyDamageLiabilityDeductiblePerOccurrence: string,
      termLocationData: array.isRequired,
    }),
    propertyAddress: shape(addressProp),
    mailingAddress: shape(addressProp),
    additionalAddresses: arrayOf(shape(addressProp)),
    policyStatus: string.isRequired,
    insuredPreferences: shape(insuredPreferences),
    isCommercial: bool.isRequired,
    isBookRoll: bool.isRequired,
  }),
};

export const formFieldProps = {
  name: string,
  value: oneOfType([string, bool, object]),
  onChange: func,
  onBlur: func,
};

export const formProps = {
  touched: object,
  errors: object,
};
