export default {
  loginPage: {
    reset: "You have successfully reset your password.",
    verification: "Your email has been successfully verified.",
    login: "Log in to SageSure Account",
    loginDescription:
      "Make a payment, view policy details, download important policy documents, report and view claims, and more.",
    username: "Email",
    usernamePlaceholder: "Enter username or email address",
    password: "Password",
    loginButton: "Log in",
    errorMsg: "We're having technical difficulties. Please try again later.",
    registerButton: "Register",
    forgotPasswordLink: "Forgot Password?",
    forgotPassword: (url, AnchorWrapper, onClick) => (
      <>
        Forgot{" "}
        <AnchorWrapper to={url} onClick={onClick}>
          password
        </AnchorWrapper>
        ?
      </>
    ),
    registerAccount: (url, AnchorWrapper, onClick) => (
      <>
        New here?{" "}
        <AnchorWrapper to={url} onClick={onClick}>
          Activate your account
        </AnchorWrapper>
      </>
    ),
    bannerText:
      "You can also make an express payment without logging in to your account.",
    bannerButtonText: "Make a payment",
    contactUsModal: {
      title: "Contact Us",
      questions:
        "Having questions about your policy or wanting to make changes?",
      contactRep:
        "Contact your insurance representative, the agency or broker listed on your policy declaration page.",
      otherQuestions:
        "For any other questions, contact SageSure Customer Service at ",
      phone: "(800) 481-0661",
      phoneLink: "tel: (800) 481-0661",
      businessHoursHeading: "Business hours",
      weekdays: "Monday-Friday: 8am - 9pm ET",
      weekend: "Saturday: 10am - 3pm ET",
      doneButton: "Done",
    },
  },
  loginPageV2: {
    loginDescription:
      "Get policy documents, make payments, and get help with your coverages in your account.",
    signInToAccount: "Welcome to SageSure",
    signIn: "Sign in",
    capitalOr: "OR",
    newHere: "New here?",
    activateAccount: "Activate your account",
  },
  activateUser: {
    activateAccount: "Activate your SageSure account",
    alreadyHaveAccount: "Already have an account?",
    logIn: "Log in",
    createAccount: "Create account",
    creatingAccount: "Creating account...",
    goBack: "Go back",
    lookingForPolicy: "Looking for policy...",
    useGoogle:
      "If you use Google sign in, you won’t have to remember a separate username and password for your SageSure account.",
    capitalOr: "OR",
    continueWithEmail: "Continue with email",
    couldntFindAccount: "We couldn’t find your SageSure account",
    policyMismatchError:
      "We were unable to find a SageSure insurance policy with that combination of policy number and zip code. Please try again.",
    tryAgain: "Try again",
    findPolicy: "Let’s find your SageSure policy",
    needHelp: "Need help?",
    contactUs: "Contact us",
  },
  paymentPage: {
    payment: "Pay my bill",
    paymentDescription:
      "To make a payment, you'll need your policy number, zip code, and payment information.",
    errorPaymentNoBalance:
      "You do not have a balance due at this time. Thank you for keeping your account up to date!",
    errorPaymentNotActive:
      "Looks like your policy is no longer active. Please contact your insurance representative for assistance.",
    successPayment:
      "Your payment has been processed successfully and you will receive a confirmation email shortly.",
    error: {
      title: "Oops, something went wrong!",
    },
    zipCode: "Zip code",
    tooltips: {
      zipCode:
        "If your policy has more than one insured locations, zip code of the first/primary location must be entered",
    },
  },
  propertyPage: {
    description:
      "SageSure has the following details on file for your insured property. Please review these details and let us know if anything has changed. You may be eligible for a lower insurance premium.",
    noInfo: () => (
      <>
        <b>We couldn’t find any data on record for your property.</b> Please
        contact your insurance representative to ensure your property
        information is correct.
      </>
    ),
    roof: "Roof",
    roofDescription:
      "Keeping your roof in good repair keeps your home safe and secure. Replacing your roof can lower your insurance premium.",
    pool: "Swimming pool",
    trampoline: "Trampoline",
    security: "Security",
    securityDescription:
      "Alarm systems can protect your home and property, and may even lower your insurance premium.",
    propertyCare: "Property care",
    thumbtackContractorTitle: "Find a contractor",
    thumbtackBody:
      "Need a quicker way to maintain your home’s wellness? You can find contractors on Thumbtack, one of SageSure’s trusted partners.",
    thumbtackButton: "Search for a recommended contractor",
    thumbtackPartnerText: "Partnered with Thumbtack",
  },
  inspectionWriteOut: {
    company: "Bees360",
    alert: {
      title: "Repairs required.",
    },
    title: "Required repairs",
    subtitle:
      "During the last inspection of your property, SageSure found repairs that need to be made before we can renew your policy.",
    noIssues: "None",
    stepsTitle: "Next Steps",
    stepsText: [
      "Use a licensed contractor to make the required repairs.",
      "When they’re done, send proof to your insurance representative.",
      "If we approve the repairs, we’ll work with your insurance representative to renew your policy.",
    ],
    supportTitle: "Support",
    supportText:
      "Your insurance representative can help with questions or concerns about repairs and renewals.",
    thumbtackFixText:
      "Need a roofer you can trust? Use one from our trusted partner network.",
    thumbtackFixButtonText: "Find a roofer",
    detailsTitle: "Inspection Details",
    thumbtackInfoTitle: "Our partnership with Thumbtack",
    thumbtackInfo: [
      "We partnered with Thumbtack to make it easier for you to find licensed contractors and get the work you need done.",
      "SageSure is not affiliated with or otherwise endorsing Thumbtack or the contractors listed on the Thumbtack website or app. SageSure may receive a fee from Thumbtack based on your selection of a Thumbtack contractor.",
    ],
  },
  leakbot: {
    textAddressTitle: "Send a LeakBot to this property?",
    textContactTitle: "How can LeakBot reach you?",
    textContactDesc:
      "LeakBot will send your device to you. You’ll receive a confirmation email and updates on shipping. Your phone number will only be used for alerts about your LeakBot device.",
    textReviewTitle: "Please review and confirm your order details.",
    termsA:
      "I agree to complete set up of my LeakBot within 28 days of delivery.",
    termsB:
      "LeakBot only takes a few minutes to set up. You don’t need any tools or plumbing knowledge, just download the free app and follow the on-screen instruction, then clip LeakBot onto the pipe.",
    termsC: (
      <>
        <a
          href="https://myleakbot.com/terms-and-conditions-us-nj/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>{" "}
        apply and will be included in your confirmation email.
      </>
    ),
    textGoBack: "Go back",
    textContinue: "Continue",
    welcome: "Welcome to SageSure's new leak prevention program!",
    submitBtn: "Submit order",
    errorGeneral:
      "There was an error. Please try again or contact support if this persists.",
    errorEmail: "Enter your email address: yourname@domain.com",
    errorPhone: "Enter a valid 10 digit phone number.",
    errorFirstName: "Enter a valid first name.",
    errorLastName: "Enter a valid last name",
    errorZip: "The provided zip code appears to be invalid.",
    errorTerms: "Please agree to the Leakbot set up commitment.",
    public:
      "We found a policy number that matches your email address. If this looks correct, please enter your zip code below to confirm.",
    tacUrl: "https://myleakbot.com/terms-and-conditions-us-nj/",
  },
  requestResetPage: {
    successMsg: "Please check your email for a link to reset your password.",
  },
  resetPasswordPage: {
    resetPassword: "Reset Password",
    resetDescription:
      "Enter the email address associated with your SageSure account. We will email you a password reset link.",
    email: "Email",
    emailPlaceholder: "Enter Email Address",
    reset: "Reset",
    send: "Send",
  },
  registrationPage: {
    register: "Register for an account",
    registerDescription:
      "Our online customer care center allows you to access your policy any time, anywhere.",
    choosePolicy: "Choose a policy to register",
    aboutYourself: "Tell us about yourself",
    geicoRegister: "Welcome",
    geicoDescription:
      "Our online customer care center allows you to access your policy anytime, anywhere.",
    step1Title: "STEP 1 OF 2",
    step1Subtitle: "Let’s find your policy.",
    step2Title: "STEP 2 OF 2",
    step2Subtitle: "Finally, enter your name and choose a password.",
    signup: (DSLink) => (
      <>
        By signing up, you agree to our{" "}
        <DSLink
          href="https://www.sagesure.com/terms-and-conditions/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </DSLink>{" "}
        and{" "}
        <DSLink
          href="https://www.sagesure.com/privacy-policy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </DSLink>
        .
      </>
    ),
    login: (url, AnchorWrapper) => (
      <>
        Already have an account? <AnchorWrapper to={url}>Log in</AnchorWrapper>.
      </>
    ),
    passwordValidationRules: [
      "No spaces",
      "At least 1 number",
      "At least 1 lowercase letter",
      "At least 1 uppercase letter",
      "At least 8 characters total",
      "Passwords match",
    ],
    accountSuccessfullyRegistered: "Account successfully registered.",
    redirectError: "An error occured, and we were unable to redirect you.",
    tooltips: {
      zipCode:
        "If your policy has more than one insured location, the zip code of the first/primary location must be entered here.",
    },
  },
  settingsPage: {
    title: "Settings",
    account: "Account",
    backToSettings: "< Back to Settings",
    newEmailAddress: "New email address",
    confirmEmailAddress: "Confirm email address",
    update: "Update",
    updateEmailSuccess: "Email address was updated successfully.",
    userID: "User ID:",
    password: "Password:",
    googleEnabled: "Sign in with Google is enabled.",
    googleUpdate: "Log in to your Google account to update your password.",
    editMortgageeSuccessful:
      "The mortgagee information has been successfully updated.",
    billingEmailUpdateError:
      "Something went wrong while updating the billing email address.",
    mailingUpdateReasons: {
      fixingTypo: "I'm fixing a typo in this address.",
      sendingElsewhere:
        "I still live here, but I want my documents sent elsewhere.",
      movingTo: "I'm moving to this property, but don't live here yet.",
      tempResidence:
        "I don't live permanently at this property (e.g. a summer home).",
      movedOut: "I moved and no one is living at this property.",
      intendToSell: "I moved and sold or intend to sell this property.",
      renting: "I moved and I'm renting this property to tenants.",
    },
    toastMessages: {
      success: {
        address: "Mailing address updated successfully.",
        phone: "Phone number updated successfully.",
        email: "Email address updated successfully.",
      },
      error:
        "There was an error. Please try again or contact support if this persists.",
    },
  },
  successPage: {
    noAccount: "Don't have a SageSure account?",
    activateDescription:
      "Activate your account to manage your policy in one place. You can also make a payment without logging into your account.",
    activateAccount: "Activate account",
    makePayment: "Make a payment",
  },
  formReceived: {
    received: "We’ve received your form.",
    description: "Thank you for signing!",
  },
  paperlessEnrollment: {
    loadingPageTitle: "Enrollment in Progress",
    successMessage: () => (
      <>
        <b>Thank you for enrolling in Paperless!</b>
        <br />
        All communications will be sent via email.
      </>
    ),
  },
  footer: {
    allRightsReserved:
      "SageSure Insurance Managers, LLC. All rights reserved. | Version:",
    faqs: "FAQs",
    about: "About Us",
    privacyPolicy: "Privacy Policy",
    termsAndConditions: "Terms and Conditions",
  },
  ceremony: {
    login: {
      title: "Log In",
      description:
        "Sign in with your existing SageSure credentials to connect your GEICO account.",
    },
    link: {
      title: (isPlural) => `Existing SageSure Account${isPlural ? "s" : ""}`,
      description: (isPlural, policyNumber) =>
        isPlural
          ? `We found existing SageSure accounts for policy number ${policyNumber}
      listed under the following email addresses:`
          : `We found an existing SageSure account for policy number ${policyNumber}
      listed under the following email address:`,
      prompt: (isPlural) =>
        isPlural
          ? `Do you want to link GEICO to
      one of these accounts?`
          : `Do you want to link GEICO to this
      account?`,
      linkButton: "Link Accounts",
      createAccount: "Create a New Account",
    },
    error:
      "It looks like our system is experiencing a problem right now. Please try linking your GEICO account again later.",
    errorLink: "Visit SageSure to access your account.",
  },
  paragraphs: {
    withoutLogin:
      "You can also make an express payment without logging in to your account.",
    enrollToday: "Enroll today in SageSure EasyPay and never miss a payment!",
    feesAddUp:
      "Direct bill fees add up over time. With automatic EasyPay payments, you'll avoid the fees and pay the lowest amount possible on a schedule that works best for you.",
    easypayAutoPay:
      "EasyPay automatically pays your bill on a schedule of your choice. Choose between once, twice, four times or ten times annually — as well as automatic bank payments or credit card.",
    optIn:
      "By opting in here, you’ll receive your billing statements by email only. Please confirm your billing email address below.",
    manualPay:
      "Opt in to receive your billing statements via email. Please confirm your email address below.",
    loadingPage: "It will take a few moments",
    coverageInformation:
      "Information above is shown for the first location. For coverage information for other locations please see ",
    declarationslink:
      "For more details on your current coverages please view the ",
  },
  emphasizeText: {
    remainingBalance: "Remaining balance",
    lastPayment: "Last payment",
    billingStatement: "View Billing Statement",
  },
  notifications: {
    error: "Something went wrong while updating the mortgagee.",
    success: "Successfully updated mortgagee",
  },
  paymentDueNotification: {
    paymentDue: "Payment due.",
    description: (dueDate) =>
      `To continue your coverage, please pay the amount due before ${dueDate}.`,
  },
  signatureNotification: {
    signatureRequired: (firstName) =>
      `${firstName}, your signature is required.`,
    pleaseSignForm: "Please sign your policy form.",
    signNow: "Sign now",
    generating: "Generating",
    errorBold: "There was an error.",
    error: "Please refresh to try again or contact support if this persists.",
    success: "We’ve received your form.",
    successBold: "Thank you for signing!",
    loading: "Checking if you have forms to sign...",
    noForms: "You have no forms to sign at this time.",
  },
  iconTitles: {
    houseIcon: "house icon",
    storeIcon: "store icon",
    caretDown: "caret down",
    caretUp: "caret up",
    addIcon: "add icon",
    pdfIcon: "pdf icon",
    profileIcon: "profile icon",
  },
  form: {
    label: {
      firstName: "First name",
      lastName: "Last name",
      emailAddress: "Email address",
      confirmAddress: "Confirm email address",
      addressLine1: "Address line 1",
      addressLine2: "Address line 2",
      addressLine2Optional: "Address line 2 (optional)",
      city: "City",
      state: "State",
      password: "Password",
      newPassword: "New password",
      reEnterPassword: "Re-enter password",
      confirmPassword: "Confirm password",
      confirmNew: "Confirm new password",
      currentPassword: "Current password",
      reasonForUpdate: "Reason for update",
      details: "Details",
      optionalDetails: "Details (optional)",
    },
    placeholder: {
      emailAddress: "Enter email address",
      confirmAddress: "Confirm email address",
      confirmNew: "Confirm new password",
      password: "Enter password",
      newPassword: "Enter new password",
      reEnterPassword: "Re-enter password",
      details:
        "Please provide more details about this address change. If necessary, our underwriting department may contact you for more information.",
      mailingUpdateReason: "Select reason for updating address",
    },
  },
  help: {
    emailNotValid: "Please enter a valid email address.",
    addressesMismatch: "Both Email addresses should match.",
    zipCodeInvalid: "Please enter a valid zip code",
    stateInvalid: "Please select a valid state",
    cityInvalid: "Please enter a valid city name",
    apartmentInvalid: "Please enter a valid address",
    streetInvalid: "Please enter a valid address",
    loanInvalid: "Please enter a valid loan number",
    mortgageeInvalid: "Please enter a valid mortgagee name",
    errorOccured: "An error occured while fetching this data",
    phoneMismatch: "Both phone numbers should match.",
    reasonForUpdateRequired: "Please enter the reason for the update",
    detailsRequired: "More details are required",
  },
  buttons: {
    reset: "RESET",
    makePayment: "Make Payment",
    makeAPayment: "Make a payment",
    loading: "Loading...",
    reportClaim: "Report a new Claim",
    seeAll: "See all",
    seeMore: "See more",
    viewAllFAQS: "View all FAQs ›",
    edit: "Edit",
    editSettings: "Edit Settings",
    cancel: "Cancel",
    update: "Update",
    updateEmail: "Update Email Address",
    updateMailAddress: "Update Mailing Address",
    updating: "Updating...",
    enrollInEasyPay: "Enroll in EasyPay",
    updateEasyPay: "Update EasyPay",
    loadingPaymentPlan: "Processing...",
    back: "< Back",
    backToBillingOverview: "< Back to Billing Overview",
    backToSettings: "< Back to Settings",
    viewAllFaqs: "View all FAQs >",
    cancelEnrollment: "Cancel Enrollment",
    cancelUpdate: "Cancel Update",
    backToClaimsOverview: "< Back to Claims Overview",
    backToLogin: "<< Log in",
    closeWithoutSaving: "Close without saving",
    keepEditing: "Continue editing",
    backToGeico: "<< Back to GEICO.com",
    remindMeLater: "Remind me later",
    paperlessEnroll: "ENROLL IN PAPERLESS BILLING",
    continue: "CONTINUE",
    register: "REGISTER",
    registerLower: "Register",
    login: "LOG IN",
    addPolicyShort: "Add policy",
    removePolicy: "Remove policy from account",
    removePolicyShort: "Remove policy",
    enroll: "Enroll",
    easyPayEnroll: "Enroll in EasyPay",
    change: "Change",
    close: "Close",
    noThanks: "No thanks",
    save: "Save",
    submit: "Submit",
    submitting: "Submitting...",
  },
  documents: {
    currentInvoice: "Current Invoice",
    policyDeclarations: "Policy Declarations",
    invoice: "Invoice",
    policyPackage: "Policy Package",
  },
  navigation: {
    logout: "Log Out",
    settings: "Settings",
  },
  userConfirmationPrompt: {
    message: "Are you sure you want to leave this page?",
  },
  titles: {
    billing: "Billing",
    claims: "Claims",
    support: "Support",
    overview: "Overview",
    documents: "Documents",
    property: "Property",
    propertyDetails: "Property details",
    keyDocuments: "Key Documents",
    policy: "Policy",
    payment: "Payment",
    easyPayEnrolled: "EasyPay Enrolled",
    settings: "Settings",
    containers: {
      upcomingPayments: "Upcoming payments",
      claimInformation: "Claim information",
      insuranceCarrier: "Insurance carrier",
      insuranceRepresentative: "Insurance representative",
      sagesureCustomerCare: "SageSure customer care",
      billingOverview: "Billing overview",
      coverages: "Coverages",
      details: "Details",
      frequentlyAskedQuestions: "Frequently sked Questions",
      contactSupport: "Contact support",
      claimsSupport: "Claims support",
      billingSummary: "BILLING SUMMARY",
      currentBalanceDue: "CURRENT BALANCE DUE",
      history: "HISTORY",
      paymentInformation: "PAYMENT INFORMATION",
      mortgageeInformation: "MORTGAGEE INFORMATION",
      activeClaims: "Active Claims",
      pastClaims: "Past Claims",
      reportClaim: "REPORT A CLAIM",
      paymentPlan: "PAYMENT PLAN",
      paymentFrequency: "PAYMENT FREQUENCY",
      reviewUpdates: "Review updates",
    },
  },
  paymentPlanSaved: {
    enrolledTitle: "Congratulations!",
    enrolledSubtitle: "You're enrolled in EasyPay.",
    updatedTitle: "Success!",
    updatedSubtitle: "You've updated your EasyPay settings.",
    text: "Your change will take effect in no more than two business days. We will email you with a confirmation of your change once the update is complete.",
  },
  faqs: {
    accessDocuments: "How can I access my policy documents?",
    resetPassword: "How do I reset my password?",
    recoverUsername: "How can I recover my username?",
    makePayment: "How can I make a payment?",
  },
  dataTable: {
    allOtherPerils: "All Other Perils",
    amount: "Amount",
    billingEmail: "Billing email",
    billingEmailAddress: "Billing Email Address",
    billingPlan: "Billing Plan",
    billingSettings: "Manage billing information in",
    building: "Building",
    businessPersonalProperty: "Business Personal Property",
    card: "Card",
    paymentDetails: "Payment details:",
    cardEndingIn: "ending in",
    claimAdjuster: "Claim Adjuster",
    claimNumber: "Claim Number",
    date: "Date",
    dateEnrolled: "Date Enrolled",
    dateRepoted: "Date Reported",
    doingBusinessAs: "Doing Business As (DBA):",
    description: "Description",
    document: "Document",
    documentsEmail: "Documents email",
    documentsSettings: (MSSLink) => (
      <>
        Manage documents information in{" "}
        <MSSLink to="/my/settings/paperless">Settings</MSSLink>
      </>
    ),
    dueDate: "Due Date",
    dwelling: "Dwelling",
    easypayMethod: "EasyPay method",
    email: "Email:",
    emailAddress: "Email address:",
    emailPlaceholder: "Enter email address",
    emailPlaceholderExample: "alee@domain.com",
    emailLabel: "Email",
    enrolled: "Enrolled",
    enterYourEmail: "Enter your email",
    fax: "Fax:",
    firstName: "First name",
    firstNamePlaceholderExample: "Aubrey",
    grandTotal: "Grand Total",
    hours: "Hours:",
    hurricane: "Hurricane",
    insuredEmail: "Insured email:",
    invoiceDate: "Invoice date",
    invoiceAmount: "Invoice amount",
    lastName: "Last name",
    lastNamePlaceholderExample: "Lee",
    liability: "Liability",
    lossOfUse: "Loss of use",
    lossType: "Loss type",
    mailingAddress: "Mailing address:",
    medicalExpense: "Medical expense",
    medicalPayments: "Medical payments",
    minimumPayment: "Minimum payment",
    otherPropertyAddresses: "Other property address(es):",
    otherStructures: "Other structures",
    paperlessBilling: "Paperless billing",
    payPlan: "Payment plan:",
    paymentDueDate: "Payment due date",
    paymentMethod: "Payment method",
    payments: "Payments",
    personalLiability: "Personal liability",
    personalProperty: "Personal property",
    phone: "Phone:",
    policyDocuments: "Policy documents",
    policyDocumentsEmail: "Policy documents email",
    policyGrandTotal: "Policy grand total:",
    policyHolder: "Policyholder:",
    policyNumber: "Policy number",
    policyNumberPlaceholderExample: "ABC123456789",
    policyPremium: "Policy premium:",
    policyTerm: "Policy term:",
    premium: "Premium",
    primary: "Primary",
    primaryMortgagee: "Primary mortgagee",
    propertyDamage: "Property damage",
    processingFees: "Processing fees (per policy term)",
    propertyAddress: "Property address:",
    propertyAddressZipCode: "Property zip code",
    secondary: "Secondary",
    serviceFees: "Service Fees",
    status: "Status:",
    testAgency: "SageSure CRU4 Test Agency",
    totalBalance: "Total Balance",
    web: "Web:",
    wellsFargo: "Wells Fargo Bank, NA #472",
    windHail: "Wind/Hail",
    zipCode: "Zip code",
    zipCodePlaceholderExample: "42518",
    zipCodePlaceholder: "Enter zip code",
  },
  sitemap: {
    about: "About",
    contact: "Contact",
    faqs: "FAQs",
    terms: "Terms",
    privacy: "Privacy",
  },
  info: {
    premium:
      "Your policy premium will be paid by your mortgage company from your escrow account.",
    grandTotal:
      "Your policy's grand total will be paid by your mortgage company from your escrow account.",
    sorryMessage:
      "We’re sorry to hear you’ve experienced a loss. As you enter your claim information, it will appear in this sidebar, where you can review your updates before finalizing them.",
    version: (versionNb) => `Version ${versionNb}`,
    copyright: () => `© ${new Date().getFullYear()} SageSure`,
    forQuestions: {
      contact: (Hotline) => (
        <>
          For questions about existing claims, please contact us at <Hotline />.
        </>
      ),
    },
    assistance: "For immediate assistance, see ",
    reportClaim: {
      description: (phone) =>
        `You can report a claim online—it usually takes just five minutes. You can also report a new claim by contacting the Claims Department${
          phone ? ` at ${phone}` : ""
        }.`,
    },
    noData: (dataType, singular) =>
      `There ${singular ? "is" : "are"} no ${dataType} available at this time.`,
    noMortgagees: "No Mortgagee Associated with this Policy",
    bop10PayInfo:
      "10 Pay Payment Plan requires EasyPay enrollment. Please enroll in EasyPay to set up automatic payments.",
    noPaymentDue:
      "Thank you for keeping your account current: no payment is due.",
    automaticallyWithdrawn:
      "EasyPay payments are automatically withdrawn on your due date: no extra payment is needed at this time.",
  },
  addresses: {
    invoicedAddress: {
      street: "191-103 Integer Varsity Avenue South",
      apartment: "Apt. 1900",
      state: "San Antonio, TX 08219",
    },
    testAgency: {
      street: "747 3rd Ave, 30th FL",
      state: "New York, NY 10017",
    },
    housePolicy: {
      street: "191-103 Integer Varsity Avenue",
      policyNumber: "Policy Number: ",
    },
  },
  easypayEnroll: {
    dayText:
      "We’ll email your next payment date and amount when you finish signing up.",
    goBack: "Go back",
    accountType: "Account type",
    cardNumber: "Card number",
    continue: "Continue",
    reviewAndConfirm: "Please review and confirm your payment details.",
    routingNumberPlaceholder: "123456789",
    accountNumberPlaceholder: "111222333444",
    cardNumberPlaceholder: "0000-0000-0000-0000",
    paperlessDocuments: "Paperless policy documents",
    zipCode: "Zip code",
    paymentPlan: "Payment plan",
    processingDay: "Processing day",
    paymentMethod: "Payment method",
    termsAndConditions: "Terms and conditions",
    successToast: {
      title: "Thanks for signing up for EasyPay!",
      description:
        "We’ll email your next payment date and amount shortly. It may take a few days to see your payment information updated here.",
    },
  },
  paymentUpdate: {
    // TODO: deprecated?
    errors: {
      errorSaving:
        "There was a problem submitting your EasyPay enrollment update.",
    },
    information: {
      direct:
        "With Direct Bill, Sage Sure will invoice your policy premium in installments. You can choose a payment frequency of one, two, four, or ten payments over the course of your policy term.",
      easyPay:
        "With EasyPay, Sage Sure will automatically process your payment via ACH transfer or debit/credit card payment. You can choose a payment frequency of one, two, four, or ten payments over the course of your policy term.",
      mortgageeEscrow:
        "With mortgagee escrow payments, your policy premium will be withdrawn from the escrow account associated with your mortgage. Your payment frequency will be determined by your bank.",
      cardsTitle: "Test Cards Title",
      cardsInfo: "Test cards Info",
    },
    frequencies: {
      paymentOne: "Payment 1",
      paymentTwo: "Payment 2",
      paymentsTwoFour: "Payments 2-4",
      paymentsTwoTen: "Payments 2-10",
    },
    paymentMethod: {
      currentMethod: "Current Payment Method",
      switchMethod: "Switch Payment Method",
      mortgageeHighlightedText:
        "When paying visa mortgagee escrow, your payment frequency will be determined by your bank",
      tooltips: {
        easypayReview:
          "If you are enrolled in EasyPay with a credit card, the payment processor will charge a 2.99% processing fee on payments.",
        easyPayACH: "SageSure accepts the following credit cards:",
        easyPayCCReview:
          "SageSure works with an unaffiliated third party (One, Inc.) to process credit card transactions. Due to the high cost of processing credit card payments, One, Inc. charges a processing fee of 2.99%. This fee does not go to SageSure.",
      },
    },
    savings: {
      easyPay: () =>
        "Save $28 in Direct Bill service fees by switching to EasyPay ACH payments.",
    },
  },
  propertyProfile: {
    successToast: (
      <p>
        <b>Thank you for submitting this update! </b>
        Please check your email for a message from SageSure requesting the roof
        contract and photos of your roof from all sides of your home, which are
        required to validate this change.
      </p>
    ),
    pleaseReview: "Please review your updates",
    submitUpdates: "Submit updates",
    submitting: "Submitting...",
    goBack: "Go back",
    continue: "Continue",
    fenceV1: "Pool fence",
    error:
      "There was an error. Please try again or contact support if this persists.",
    poolDataTitles: {
      poolType: "Type",
      fence: "Permanent fenced or locked structure",
      divingBoardOrSlide: "Diving board or slide",
      poolCovering: "Unfilled and not completely covered",
      immovablePoolLadder: "Immovable ladder",
      unlockedPoolGate: "Attached to deck without a locking gate",
      fenceBulletAbove:
        "Your pool is at least 4 feet tall or has a fence that’s 4 feet or taller.",
      fenceBulletIn: "Your pool is surrounded by a fence 4 feet or taller.",
      fenceBulletTwo:
        "Your pool fence has a padlocked or self-locking or self-latching gate.",
    },
  },
  updateRoof: {
    pleaseReview: "Please review your updates",
    roofDescription:
      "Keeping your roof in good repair keeps your home safe and secure. Replacing your roof can lower your insurance premium.",
    yearInstalled: "Year roof installed / replaced:",
    roofMaterial: "Roof material",
    documentation: "Documentation",
    documentationDescription: (
      <p>
        After submitting this update, we will send an email requesting your{" "}
        <b>roof contract</b> and{" "}
        <b>photos of your roof from all sides of your home </b>
        to validate this change.
      </p>
    ),
    whatRoofMaterial: "What is your roof made of?",
    notSure: "I'm not sure",
    return: "Return to question",
  },
  updatePool: {
    pleaseUpdate: "Please update your pool details.",
    pleaseConfirm: "Please confirm your property update.",
    confirmDescription:
      "I confirm that I no longer own or have never owned a pool on my property. I agree to notify SageSure in the future if I add a pool, and I understand liability coverage is excluded.",
    supportSidebar:
      "If you have any additional questions about how we can support you and give you the best coverage for your pool, please reach out to your insurance representative.",
    poolReview:
      "Maintaining accurate information about your pool helps us ensure you have the best coverage for your property.",
    inGround: "Yes, an in-ground pool",
    aboveGround: "Yes, an above-ground pool",
    questions: {
      poolType: "Do you have a swimming pool on the property?",
      poolFence: "Is your pool fenced?",
      fenceDetails: "Please update your fence details.",
      fenceHeight: "Is the fence at least 4 feet tall?",
      poolFenceLockingGate: "Does the fence have a locking gate?",
      boardOrSlide: "Does your pool have a diving board or slide?",
      divingBoard: "Does the pool have a diving board?",
      poolSlide: "Does the pool have a slide?",
      aboveGroundPoolMinimumHeight: "Is your pool less than 4 feet tall?",
      poolFilled: "Is your pool filled?",
      poolCovered: "Is your pool completely covered?",
      poolAttachedToDecking: "Is your pool attached to decking?",
      poolDeckGateLocks: "Does your pool deck have a gate that locks?",
      poolImmovableLadder: "Does your pool have an immovable ladder?",
    },
    sidebars: {
      titles: {
        support: "Support",
        poolSafety: "Pool safety",
        fencing: "Fencing",
        nextSteps: "Next steps",
        poolCovers: "Pool covers",
        gateLock: "Gate locks",
      },
      content: {
        support: (
          <p>
            If you have any additional questions about how we can support you
            and give you the best coverage for your pool, please reach out to
            your insurance representative.
          </p>
        ),
        poolSafety: (
          <p>
            Secure fencing <b>around the perimeter of your pool</b> can help
            prevent accidents.
          </p>
        ),
        fencing: (
          <p>
            A fence height of <b>at least 4 feet</b> with a <b>locking gate</b>{" "}
            is recommended for pool safety.
          </p>
        ),
        nextSteps: (
          <p>
            After submitting this update, you will receive a copy of your
            responses by email. Your insurance representative will contact you
            if further action is needed.
          </p>
        ),
        poolCovers: (
          <p>
            Complete and <b>secure covering with permanent anchors</b> can
            prevent accidental injury from occurring in your unfilled pool.
          </p>
        ),
        gateLock: (
          <p>
            The gate should be <b>self-locking, self-latching</b>, or be secured
            by a <b>combination lock or padlock.</b>
          </p>
        ),
      },
    },
    reviewTitles: {
      poolType: "Type",
      poolFence: "Fenced",
      poolFenceMinimumHeight: "Fence at least 4ft tall",
      poolFenceLockingGate: "Fence with locking gate",
      divingBoard: "Diving board",
      poolSlide: "Slide",
      aboveGroundPoolMinimumHeight: "Pool less than 4ft tall",
      poolFilled: "Filled",
      poolCovered: "Covered",
      poolAttachedToDecking: "Attached to decking",
      poolDeckGateLocks: "Decking has locking gate",
      poolImmovableLadder: "Immovable ladder",
    },
  },
  updateSecurity: {
    burglarAlarm: "Burglar alarm:",
    fireAlarm: "Fire alarm:",
    pleaseUpdate: "Please update your security details.",
    doYouHaveBurglarAlarm: "Do you have a burglar alarm system?",
    doYouHaveFireAlarm: "Do you have a fire alarm system?",
    securityDescription:
      "Alarm systems can protect your home and property, and may even lower your insurance premium.",
    no: "No",
    yesCentral: "Yes, a central alarm",
    yesLocal: "Yes, a local alarm",
    nextSteps: "Next steps",
    documentationDescription: (
      <p>
        If you have installed a new <b>central alarm system</b>, you’ll receive
        an email after this update requesting your <b>alarm certificate</b> to
        validate this change.
      </p>
    ),
    nextStepsDescription: (
      <p>
        If you are adding a new <b>central alarm system</b>, you will receive an
        email from SageSure requesting the <b>alarm certificate</b> after you
        submit this update.
        <br />
        <br />
        If you are adding a local alarm system or removing an alarm system,
        there are no further steps needed.
      </p>
    ),
  },
  updateTrampoline: {
    doYouHaveTrampoline: "Do you have a trampoline on the property?",
    pleaseConfirm: "Please confirm your property update.",
    iAgree: "Yes, I agree.",
    confirmation:
      "I confirm that I no longer own or have never owned a trampoline on my property. I agree to notify SageSure in the future if I add a trampoline, and I understand liability coverage is excluded.",
    doYouHavePadding: "Does your trampoline have shock-absorbing padding?",
    supportHeadline: "Support",
    supportBody:
      "Your policy may contain liability coverage restrictions regarding your trampoline, please contact your insurance representative for further guidance.",
    safetyHeadline: "Trampoline safety",
    safetyBody: (
      <>
        Shock absorbing <b>padding over the springs, hooks and frame</b> is
        highly recommended for trampoline safety.
      </>
    ),
    reviewHeadline: "Next steps",
    reviewBody:
      "After submitting this update, an email will be sent to you and your insurance representative documenting any changes to your property profile.",
    reviewDescription:
      "Keeping your property information accurate and up-to-date helps us ensure that you have the best coverage for your needs.",
    reviewShockabsorption: "Shock-absorbing padding",
  },
  formFields: {
    claimsForm: {
      dateOfLoss: "When did the loss occur?",
      dateOfLossSubtitle:
        "If the exact time of the loss is unknown please leave the time field blank or report an approximate time.",
      dateOfLossExisting: (submissionNumber, carrierPhone) =>
        `Our records show there's an existing reported loss (Submission #${submissionNumber}) on the date specified above. We're very sorry to hear you experienced an additional loss on that day. To get immediate assistance, please contact your carrier directly at ${carrierPhone} to report any additional loss information.`,
      timeOfLossPlaceholder: "HH:MM",
      lossAtInsuredProperty:
        "Did the loss occur at the insured property address?",
      yes: "Yes",
      no: "No",
      propertyAddress: "Property Address",
      typeOfLoss: "Select the type of loss that best describes your incident.",
      typeOfLossPlaceholder: "Select Loss Type",
      lossDescriptionTitle: "Describe your loss:",
      lossDescription:
        "Please be as detailed as possible. Describe the extent of damage, rooms involved, etc. You’ll be able to share additional information about your claim with your carrier later in the process as well, this is just a starting point.",
      lossDescriptionPlaceholder:
        "Plumbing leak in kitchen, flooded kitchen, dining and family room area.  Carpet soaked, wood floors buckling and furniture soaked and damaged.",
      claimSubmittedByPolicyholder:
        "Is this claim being submitted by the policyholder?",
      addApartment: "Add Apartment/Suite/Unit Number",
      remove: "Remove",
      apartment: "Apartment/Suite/Unit Number",
      contactRegardingThisForm: "Who should we contact regarding this claim?",
      name: "Name",
      relationship: "Relationship to Policyholder",
      relationshipPlaceholder: "Select Relationship to Policyholder",
      primaryPhoneNumber: "Primary Phone Number",
      primaryPhoneNumberType: "Primary Phone Type",
      alternatePhoneNumber: "Alternate Phone Number",
      alternatePhoneNumberType: "Alternate Phone Type",
      phoneNumberTypePlaceholder: "Select Phone Type",
      contactRegardingClaim: "Who should we contact regarding this claim?",
      emailAddress: "Email Address",
      wantsToReceiveSMSUpdates:
        "I’d like to receive updates about this claim via SMS.",
      reviewClaimSubmit: "Review Claim + Submit",
      submitClaim: "Submit Claim",
      continueEditing: "Continue Editing",
      cancelClaim: "Cancel Claim",
      errors: {
        dateOfLossRequired: "Date of loss must be provided.",
        dateOfLossFutureDateError: "Date of loss cannot be a future date.",
        locationOfLossError:
          "Address of where the loss occurred must be specified.",
        typeOfLossRequired: "Type of loss must be specified.",
        lossDescriptionRequired: "Description of your loss must be provided.",
        nameRequired: "Name must be provided.",
        relationshipRequired: "Relationship must be provided.",
        phoneRequired: "Phone number must be provided.",
        phoneFormatError: "Phone number format is invalid.",
        phoneTypeRequired: "Phone type must be specified.",
        emailFormatError: "Email address format is invalid.",
        emailRequired: "Email address must be provided.",
        wantsToReceiveSMSUpdatesError:
          "To receive text messaging updates about this claim, you must enter a Mobile phone number.",
      },
    },
    bankAccountType: "Bank account type",
    bankStatementName: "Authorized individual name",
    bankStatementNamePlaceholder: "Enter name",
    financialInstitutionName: "Name of financial institution",
    financialInstitutionNamePlaceholder: "Enter name",
    accountNumber: "Account number",
    accountNumberPlaceholder: "Enter account number",
    confirmAccountNumber: "Confirm account number",
    confirmAccountNumberPlaceholder: "Re-enter account number",
    routingNumber: "9-digit bank (ABA) routing number",
    routingNumberPlaceholder: "Enter routing number",
    confirmRoutingNumber: "Confirm 9-digit bank (ABA) routing number",
    confirmRoutingNumberPlaceholder: "Re-enter routing number",
    paymentProcessingDate: "Payment processing date",
    paymentProcessingTooltip:
      "If the draft date you select is within the next three days, the first withdrawal will not occur until next month and will include both this month’s and next month’s draft amounts.",
    achAuthorize:
      "I authorize SageSure Insurance Managers, LLC to perform scheduled electronic fund transfer debits from the account identified above.",
    achAuthorizeHint:
      "This authorization is to remain in full force and effect until SageSure Insurance Managers, LLC receives Authorized Individual's written request to terminate three business days or more before the next scheduled transfer date.",
    easypayMethod: "EasyPay method",
    easypayMethodCC: "Debit/credit card",
    easypayMethodACHTooltip:
      "ACH (automated clearing house) transfer uses your account and routing numbers to directly transfer payment from your bank account.",
    cardholderName: "Cardholder name",
    cardholderNamePlaceholder: "Enter name",
    policyDocumentsEmail: "Policy Documents Email",
    confirmCardNumber: "Confirm card number",
    confirmCardNumberPlaceholder: "Re-enter credit card number",
    cardExpirationDate: "Card expiration date",
    cardExpirationDatePlaceholder: "MM / YYYY",
    cardZipCodePlaceholder: "Zip code",
    ccAuthorize:
      "I authorize SageSure Insurance Managers, LLC and One, Inc. to perform scheduled payments from the card identified above. SageSure Insurance Managers, LLC uses One, Inc. as their third-party provider. If I choose to pay with a credit card or debit card, the total amount to be charged to the card will be the policy payment amount plus a 2.99% processing fee.",
    ccAuthorizeHint1:
      "SageSure believes in keeping costs low to keep premiums low. SageSure works with an unaffiliated third party (One, Inc.) to process credit card transactions. Due to the high cost of processing credit card payments, One, Inc. charges a processing fee of 2.99%. This fee does not go to SageSure. Customers paying with ACH Transfer are not charged a processing fee.",
    ccAuthorizeHint2:
      "This authorization is to remain in full force and effect until SageSure Insurance Managers, LLC receives Authorized Individual’s written request to terminate three business days or more before the next scheduled transfer date.",
    bopAdditionalAcknowledgment:
      "I understand that if a down payment has not been made, SageSure will draft a payment for the outstanding amount within 24-48 hours.",
    surplusFeeTooltip:
      "Includes a Surplus Contribution, which is 5% of your policy premium excluding fees",
  },
  formFieldValidations: {
    required: "Required",
    username: "Please enter a username or email",
    password: "Please enter a password",
    firstName: "Please enter a first name.",
    lastName: "Please enter a last name.",
    emailRequired: "Please enter an email address.",
    emailInvalid: "Please enter a valid email address.",
    bankStatementName: "Please enter a valid name.",
    financialInstitutionName: "Please enter a valid institution name.",
    accountNumber: "Please enter a valid account number.",
    confirmAccountNumber: "Account numbers must match.",
    routingNumber: "Please enter a valid routing number.",
    confirmRoutingNumber: "Routing numbers must match.",
    cardholderName: "Please enter a valid name.",
    cardNumber: "Please enter a valid card number.",
    confirmCardNumber: "Card numbers must match.",
    cardExpirationDate: "Please enter a valid date.",
    cardZipCode: "Please enter a valid zip code.",
    policyNumber: "Please enter your 10 to 12 digit policy number.",
    phoneNumber: "Please enter a valid 10 digit phone number",
    twelveDigitPolicyNumber: "Please enter your 12 digit policy number.",
    zipCode: "Please enter a valid 5-digit property zip code.",
    confirmEmail: "Email addresses must match.",
  },
  paymentCard: {
    payment: "Payment",
    firstPayment: "Payment 1:",
    payemnts24: "Payments 2-4:",
    payments210: "Payments 2-10:",
    payment2: "Payment 2:",
    payments: "Payments",
    processingFees: "Processing Fees:",
    serviceFees: "Service Fees:",
  },
  paperlessBillingBanner: {
    title: "Paperless",
    subtitle:
      "Skip the extra mail with paperless billing statements from SageSure.",
    content: "Opt to receive your billing statements via email.",
    submit: "Enroll in Paperless",
  },
  reviewUpdates: {
    subtitle: "Here’s your updated payment plan:",
    emailConfirmation:
      "We’ll email you within 3 business days to confirm the date your first payment will be withdrawn.",
  },
  authorize:
    "I authorize SageSure Insurance Managers, LLC to perform scheduled electronic fund transfer debits from the account identified above.",
  mortgageeEscrow: {
    description:
      "Withdraw your policy premium from the escrow account associated with your mortgage.",
    primaryMortgagee: "Primary Mortgagee",
    secondaryMortgagee: "Secondary Mortgagee",
    loanNumber: "Loan Number",
    addSecondaryMortgagee: "Add Secondary Mortgagee",
  },
  enrollInEasyPayPage: {
    title: "ENROLL IN EASYPAY",
    description:
      "Automatically pay your policy premium via bank transfer or debit/credit card payment. Our automatic payment option eliminates your installment fee.",
  },
  extendedCoverages: {
    title: "Extended coverages",
    getStarted: "Get started",
    seeMore: "See more...",
    buyNow: "Buy now using your SageSure account",
    callYourRep: "Call your insurance representative",
    purchaseCoverage: "Purchase Coverage",
    purchaseCoverageButton: "Purchase coverage",
    emailYourRep: "Email your insurance representative",
    connectWithYourRep: "Connect with your insurance representative",
    sendMessage: "Send message",
    learnAboutCoverage: "Learn more about this coverage",
    downloadPDF: "Download PDF",
    loading: {
      title: "We're checking your coverage prices.",
      description:
        "This will take a few moments. Please do not close your browser or refresh the screen.",
    },
    step: {
      coverages: {
        title: "Which coverages would you like to add?",
      },
      review: {
        title: "Let’s review your purchase",
      },
    },
    coversUpTo: "Covers up to",
    annualPrice: "Annual price of coverage",
    startDate: "Coverage start date",
    renewalDate: "Policy renewal date",
    yourPrice: "Your pro-rated price",
    updateNotice:
      "The cost of this coverage will be reflected on your next billing statement.",
    processingSubheading: "We are processing your request.",
    processingMessage:
      "This will take a few moments. Please do not close this window.",
    calculatingSubheading: "We are calculating your coverage pricing.",
    confirmationSubheading: "Thank you for your purchase.",
    confirmationMessage: (date) =>
      `Your coverage will go into effect on ${date}. Billing changes will be reflected in your next billing statement.`,
    confirmedTermsTitle: "Here is how your premium changed",
    previousPremium: "Your previous premium",
    newPremium: "Your new premium",
  },
  supportSidebars: {
    insuranceRep:
      "Your insurance representative can assist with any questions or concerns about your policy.",
    insuranceCarrier:
      "Your carrier can assist with any questions or concerns about your policy.",
    leaveMessage: "Leave a message",
  },
  policyBlock: {
    homeownersInsurance: "Homeowner's insurance",
    commercialInsurance: "Commercial insurance",
    policyNumber: "Policy number:",
  },
};
