import { Heading, Item, Select } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { PropertyUpdateRoofContext, PropertyUpdateRoofContextState } from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import styles from "./property-update-roof.module.scss";

export const RoofConstructionYear = () => {
  const { state, setFields } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  const options: { name: string; id: number }[] = [];
  const curYear = new Date().getFullYear();
  for (let year = curYear; year >= state?.prevRoofYear; year--) {
    options.push({ name: year.toString(), id: year });
  }

  return (
    <PropertyUpdateLayout
      step={1}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !state?.roofYear,
        primaryPath: "material",
      }}
    >
      <div>
        <Heading size="md">When did you replace your roof?</Heading>
        <div className={styles.selectWrapper}>
          <Select
            selectedKey={state?.roofYear}
            placeholder="Select a year"
            items={options}
            onSelectionChange={(selected: number) => {
              setFields({ roofYear: selected });
            }}
            aria-label="select-year"
            fluid
          >
            {(item) => <Item>{item.name}</Item>}
          </Select>
        </div>
      </div>
    </PropertyUpdateLayout>
  );
};
