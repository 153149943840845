import { useNavigate } from "react-router-dom";

import { Button, Heading, Span, SpotIcon } from "@icg360/design-system";

import UnauthLayout from "components/shared/unauth-layout";

import styles from "./registration-confirmation.module.scss";

const RegistrationConfirmation = () => {
  const navigate = useNavigate();
  return (
    <UnauthLayout hideNav>
      <div className={styles.content}>
        <SpotIcon name="Email" appearance="bold" />
        <Heading size="lg">Verify your email</Heading>
        <Span>Check your email to continue setting up your account.</Span>
        <Button onClick={() => navigate("/")}>Log in</Button>
      </div>
    </UnauthLayout>
  );
};

export default RegistrationConfirmation;
