import { useFlags } from "utils";

import {
  RadioButtonField as RadioButtonFieldDS,
  RadioButtonGroup as RadioButtonGroupDS,
} from "./radio-button-field";
import {
  RadioButtonFieldOld,
  RadioButtonGroupOld,
} from "./radio-button-field-old";

export const RadioButtonField = (props) => {
  const { radioButtonsUseDs } = useFlags();
  return radioButtonsUseDs ? (
    <RadioButtonFieldDS {...props} />
  ) : (
    <RadioButtonFieldOld {...props} />
  );
};

export const RadioButtonGroup = (props) => {
  const { radioButtonsUseDs } = useFlags();
  return radioButtonsUseDs ? (
    <RadioButtonGroupDS {...props} />
  ) : (
    <RadioButtonGroupOld {...props} />
  );
};
