import { useNavigate } from "react-router-dom";

import { Button, Heading, Span } from "@icg360/design-system";

import styles from "./property-update-roof.module.scss";

export const RoofUpdateCompleted = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.completed}>
      <img
        className={styles.checkGif}
        src="/assets/images/check.gif"
        alt="green check mark animation"
      />
      <Heading size="md">Thanks! We&apos;ve got it.</Heading>
      <Span>
        We&apos;ll get back to you with an update in the next few days.
      </Span>
      <Button onClick={() => navigate("/my/property")}>Done</Button>
    </div>
  );
};
