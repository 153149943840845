import { useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone-latest";
import { useMediaQuery } from "react-responsive";

import {
  Alert,
  Button,
  Heading,
  List,
  Span,
  UiIcon,
} from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { PropertyUpdateRoofContext, PropertyUpdateRoofContextState } from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import { FileThumbnails } from "./file-thumbnails";
import styles from "./roof-proof-upload.module.scss";

type RejectionErrorDisplayInfo = {
  fileName: string;
  rejectionReason: RejectionReason;
};

type RejectionReason =
  | "File type needs be .pdf, .jpg or .png."
  | "File is too large.";

export const RoofProofUpload = () => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const { setFields, state } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );
  const files = state?.files ?? [];

  // Maximum file size in bytes
  const SIX_MILLION = 6000000;
  const rejectionMap: Record<string, RejectionReason> = {
    "file-invalid-type": "File type needs be .pdf, .jpg or .png.",
    "file-too-large": "File is too large.",
  };

  const removeFile = (fileToDelete: File & { preview?: string }) => {
    if (fileToDelete?.preview) {
      URL.revokeObjectURL(fileToDelete.preview);
    }
    const newList = (state?.files ?? []).filter(
      (file) => file !== fileToDelete
    );
    setFields({ files: newList });
  };

  const rejectionErrorList: RejectionErrorDisplayInfo[] = rejectedFiles.map(
    (fileRejection: FileRejection) => {
      const { errors } = fileRejection;

      // If the file is both too large and an invalid type, only show the invalid type error.
      const errorCode = errors.reduce((curCode, { code }) => {
        if ([curCode, code].includes("file-invalid-type")) {
          curCode = "file-invalid-type";
        } else if (code === "file-too-large") {
          curCode = code;
        }
        return curCode;
      }, "");

      return {
        fileName: fileRejection.file.name,
        rejectionReason: rejectionMap[errorCode],
      };
    }
  );

  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });

  return (
    <PropertyUpdateLayout
      step={3}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !files.length,
        primaryPath: "../review",
      }}
    >
      <div className={styles.roofProof}>
        <Heading size="md" className={styles.heading}>
          We need some proof
        </Heading>
        <Span>
          Please upload the <Span bold>contract from your roofer </Span>
          and <Span bold>photos of your roof </Span>
          from all sides of your home.
        </Span>
        <div className={styles.dropzoneWrapper}>
          <Dropzone
            noClick
            maxSize={SIX_MILLION}
            accept={{
              "image/jpg": [".jpg", ".jpeg"],
              "image/png": [".png"],
              "application/pdf": [".pdf"],
            }}
            onDropRejected={setRejectedFiles}
            onDrop={(acceptedFiles) => {
              const currentFiles = state?.files;
              setFields({
                files: [
                  ...(currentFiles ?? []),
                  ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  ),
                ] as unknown as FileList,
              });

              if (rejectionErrorList.length > 0) {
                setRejectedFiles([]);
              }
            }}
          >
            {({ getRootProps, getInputProps, isDragActive, open }) => (
              <button
                className={
                  isDragActive ? styles.dropAreaDragging : styles.dropArea
                }
                {...getRootProps()}
                onClick={open}
              >
                <UiIcon name="Document" size="md" />
                {isMobile ? (
                  <Button
                    size="sm"
                    appearance="secondary"
                    onClick={open}
                    className={styles.uploadButton}
                  >
                    Upload document
                  </Button>
                ) : (
                  <Span bold className={styles.dropAreaHeading}>
                    Drag your files here
                  </Span>
                )}
                <Span color="quiet">Up to 6MB each</Span>
                <Span color="quiet">We can handle: JPG, PNG, PDF</Span>

                {!isMobile && (
                  <div className={styles.inputLabel}>
                    <Span bold color="interactive">
                      Choose files
                    </Span>
                  </div>
                )}
                <input
                  {...getInputProps({
                    type: "file",
                    name: "files",
                    multiple: true,
                  })}
                />
              </button>
            )}
          </Dropzone>
          {rejectionErrorList.length > 0 && (
            <Alert
              className={styles.fileErrorAlert}
              title="Something went wrong while uploading the following images:"
              appearance="danger"
              description={
                <List type="ul">
                  {rejectionErrorList?.map(({ fileName, rejectionReason }) => {
                    return (
                      <li key={fileName + rejectionReason}>
                        {`"${fileName}" (${rejectionReason})`}
                      </li>
                    );
                  })}
                </List>
              }
            />
          )}
        </div>
        <FileThumbnails files={state?.files} removeFile={removeFile} />
      </div>
    </PropertyUpdateLayout>
  );
};
