import { useEffect } from "react";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";

import { Button, Heading, List, MarketingIcon } from "@icg360/design-system";

import { HeaderLogo } from "components/shared/logo-link";
import { PageFooter } from "components/shared/page-footer";
import { trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const ThankYou = () => {
  useEffect(() => {
    trackEvent("shinglesForm_success_load");
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <Container className={styles.headerWithLogin}>
          <MediaQuery minWidth={992}>
            {(matches) => (
              <>
                <HeaderLogo logo={matches ? "default" : "mobile"} />
                <Button
                  onClick={() => {
                    trackEvent("shinglesForm_success_logInClicked");
                    navigate("/");
                  }}
                  appearance="secondary"
                  size={matches ? "default" : "xs"}
                >
                  Log in
                </Button>
              </>
            )}
          </MediaQuery>
        </Container>
      </div>
      <Container className={styles.content}>
        <div className={styles.thankYouWrapper}>
          <div className={styles.shieldIcon}>
            <MarketingIcon name="ShieldHouse" size="lg" />
          </div>
          <Heading size="lg">
            Thank you for signing up for the Wind Defense Research Program!
          </Heading>
          <Heading size="md">Thing to keep in mind:</Heading>
          <List>
            <li>
              Homes in the immediate path of the hurricane will be prioritized.
            </li>
            <li>
              LiftLock&apos;s goal is to provide these homes with extra
              protection and to assess how resealed shingles perform during
              high-wind events.
            </li>
            <li>
              If your home is selected, a trained LiftLock specialist will be
              sent to reseal your shingles before the storm.
            </li>
          </List>
          <Heading size="md">
            Haven&apos;t activated your online account yet?
          </Heading>
          <div>
            Stay in touch with your policy, your payment, and more offerings at
            SageSure by logging in to your online account.
          </div>
          <Button
            fluid
            onClick={() => {
              trackEvent("shinglesForm_success_activateAccountClicked");
              navigate("/register");
            }}
          >
            Activate my online account
          </Button>
        </div>
      </Container>
      <PageFooter />
    </>
  );
};

export default ThankYou;
